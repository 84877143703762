/* Custom Icons */
.icon {
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }

  &.icon-marker {
    &:before {
      background-image: url(../images/header-store-locator-icon.svg);
      width: 18px;
      height: 24px;
      margin-right: 10px;
    }
  }

  &.icon-direction {
    padding-right: 25px;

    &:before {
      background-image: url(../images/midas-store-directions-icon.svg);
      background-position: 100% 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 1px;
    }
  }
}

.hb-nearest-store .icon.icon-direction:before {
  background-image: url(../images/icons/directions@2x.png);
}

.acf-map {
  a.icon.icon-direction:before {
    top: -2px;
    right: 3px;
  }

  a.icon.icon-direction {
    top: 7px;
  }
}

.menu-item {
  img {
    width: 73px;
    height: 73px;
    border-radius: 50%;
    margin-right: 2rem;
  }

  ._svg {
    background: #323232;
    width: 73px;
    height: 73px;
    border-radius: 50%;
    margin-right: 2rem;

    path {
      fill: white;
      background-color: #333;
    }

    .cls-2, .cls-4 {
      stroke: #ffeb00 !important;
      fill: #323232 !important;
    }
  }
}

