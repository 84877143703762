.page-template-template-store-location,
.page-template-template-franchise-location {
    .result-listing {
        height: auto;
        margin-top: 20px;
        @media (min-width:768px) {
            max-height: 90vh;
            overflow-y: auto;
          }
        .result-list {
            h4 {
                cursor: pointer;
            }

            .th-additional-details {
                display: none;
            }

            .th-additional-details-toggle {
                cursor: pointer;
            }
            &:first-child{
                padding: 0;
            }
        }
    }

    .search-field {
        @media (min-width:768px) {
            padding-left: 30px;
        }
    }
    .text-field:first-child{
            @media (max-width:991px) {
                margin-bottom: 20px;
            }
        }
}
