
.right-panel-portion {
	@media(max-width: 991px){
		padding-right: 0px;
	}
}
.single-store-gmap {
	@media(max-width: 991px){
		margin-top: 1.5rem!important;
	}
	@media only screen
	and (min-device-width: 768px)
	and (max-device-width: 991px){
		width: 44%;
	}
	@media(max-width: 767px){
		text-align: center;
		padding-right: 0px;
	}

	.btn-primary{
		@media(min-width: 992px) and (max-width: 1200px){
			font-size:0.875rem;
		}
	}

}
.single-store-reviews {
	@media only screen
	and (min-device-width: 768px)
	and (max-device-width: 991px){
		width: 56%;
	}
	@media(max-width: 767px){
		padding-right: 0px;
		text-align: center;
	}
}
.rplg{
	@media only screen
	and (min-device-width: 768px)
	and (max-device-width: 991px){
		padding-left: 15px;
	}

	 .rplg-biz-based{
		 font-size:12px!important;
	 }
	.rplg-badge2 {
		margin: 0px 10px 0 0!important;
		&:last-child{
			margin-right: 0!important;
		}

		@media(max-width: 991px){
			margin:0!important;
			&[data-provider='google'] {
				margin-right: 4% !important;
			}
		}
		@media(max-width: 380px){
			width: 48%;
		}
		.rplg-badge2-score {
			display: inline-block;
			font-size: 14px!important;
			line-height: 1rem!important;
			margin: 0 0 0 8px!important;
			vertical-align: middle!important;
		}
		.rplg-badge2-btn{
			> svg {
				width: 21px!important;
				height: 21px!important;
				@media(min-width: 1024px) and (max-width: 1199px){
					display: none!important;
				}
				@media(min-width: 360px) and (max-width: 374px){
					width: 18px!important;
					height: 18px!important;
				}
				@media(max-width: 359px){
					display: none;
				}
			}
			display: flex!important;
			align-items: center!important;
			position: relative!important;
			padding: 5px!important;
			z-index: 998!important;
			color: #333!important;
			background: #fff!important;
			border-radius: 0 0 2px 2px!important;
			transition: all .2s ease-in-out!important;
		}
		.rplg-biz-rating{
			.rplg-biz-score{
				font-size: 16px!important;
				@media(max-width: 1024px){
					font-size: 14px!important;
				}
			}
		}
	}
	.rplg-stars svg {
		width: 16px!important;
		height: 16px!important;
		@media(max-width:1024px){
			width: 13px!important;
			height: 13px!important;
		}

		margin: 0 4px 0 0!important;
		vertical-align: middle!important;

	}
}

