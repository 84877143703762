.card-news, .card-promotions{
    transition-duration: .2s;
    &:hover{
        box-shadow: 0 3px 1px rgba(0,0,0,.3);
        transform: scale(1.01);
}
}
.news, .promotions{
    .page-header{
        padding-bottom: 1.25rem;
    }
}
.filter-row {
    padding: 0 2.5rem 3.5rem;
    @media (max-width: 991px) {
        padding-bottom: 2.5rem;
    }
    @media (max-width: 767px) {
        padding: 0 0 2.25rem;
    }
    label{
        font-size: 12px;
        font-weight: 500;
        margin-right: 0.813rem;
    }
    .dropdown-toggle{
        position: relative;
        font-size: 12px;
        font-weight: 500;
        padding: 0 1.125rem;
        max-width: 320px;
        width: 100%;
        height: 35px;
        border: solid 1px $gray-900 !important;
        text-align: left;
        background-color: #fff !important;
        text-transform: none;
        @include box-shadow( inset 0 0 2px $gray-900 );
        @include border-radius( 4px );

        &:after{
            font-size: 18px;
            position: absolute;
            right: 10px;
            top: 14px;
        }
        &:focus{
            border-color: $red;
        }
    }
    .dropdown-menu{
        max-width: 320px;
        width: 100%;
        margin: -3px 0 0 -1px;
        padding: 0;
        border-top: 0;
        border-color: $gray-900;
        @include box-shadow( inset 0 0 2px $gray-900 );
        @include border-radius( 0 0 4px 4px );

        a{
            padding: 0.5rem 1.125rem;
            font-size: 12px;
            border-bottom: 1px solid #f1f1f3;
            &:last-child{
                border-bottom: 0;
            }
            &:hover{
                background-color: $yellow;
                border-color: $yellow;
            }
            
        }
    }
}
  
.card-article {
    a{
        color: $gray-800;
        text-decoration: none;
    }
    .card-body {
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(208,208,208,1) 0%, rgba(255,255,255,1) 100%);
        .fa {
            vertical-align: middle;
            padding-left: 10px;
        }
    }
   
}

.card-article .meta {
    font-size: 0.875rem;
    margin-bottom: $spacer-1;
}
  
.card-article .card-title {
    font-size: 1.5rem;
    min-height: 56px;
}
  
.social-share { 
    border-radius: 1.5rem;
    border: 1px solid #000;
    padding: 0; 
    width: 54px;
    overflow: hidden;
    .list-group-item {
        font-size: 2.25rem; 
        color: $red; 
        padding: 0;
        text-align: center;
        border: 0; 
        line-height: 1;
        i {
            vertical-align: middle;
            padding: .3rem;
        }
        &:first-child {
            padding: 10px 0 5px 0;
            background:$black !important;
            color:$yellow;
        }
        &:last-child {
            border-radius: 0 0 1.5rem 1.5rem; 
            padding: 5px 0 10px 0;
        }
        &:hover {
            background: $yellow; 
            a {
            color: $black;
            }
        }
    }
    @media (max-width: 767px) {
        margin-top: 2rem;
        width: auto;
        display: inline-block;
        border-radius: 1rem;
        overflow: hidden;
        .list-group-item{
            display: inline-block;
            width: auto;
            padding: .6rem;     
            &:first-child{
                border-radius: 0;
                padding: .6rem;       
            }
            &:last-child{
                border-radius: 0 1rem 1rem 0;
                padding: .6rem;       
            }
        }
    }
}// end of .social-share

body.article .page-title{
    display: none;
}

// Promotions Details
.location-list{
    @include list-none;
    padding-bottom: 25px;
    li{
        padding-left: 20px;
        a{
            &:hover{
                text-decoration: underline;
            }
            .fa{
                font-size: 18px;
                margin-right: 12px;
            }
        }
    }
}

.term-conditions-content{ 
    @media (min-width: 768px) {
        display: block !important;
    }
}
@media (max-width: 767px) {
    .term-conditions-wrap{
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        margin-bottom: 20px !important;
        @include clearfix;
    }
    .term-conditions-title{
        position: relative;
        padding: 27px 0 !important;
        text-align: left !important;
        margin: 0;
        cursor: pointer;

        @include accordionIcons;
    }
    .term-conditions-content{
        padding-bottom: 40px;
        @include clearfix;
    }
}