html {
  scroll-behavior: smooth;
  height: 100%;
  font-size: 100%;
  @include media-breakpoint-down(lg) {
    font-size: 95%;
  }
}

html, body {
  overflow-x: hidden;
}

body {
  color: $gray-secondary;
  font-family: $font-family-base;
  background: #FFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 600;
  margin-bottom: 1rem;
  color: $black;
  @media (max-width: 767px) {
    font-weight: 500;
  }

  &.red-dot {
    &::after {
      content: '';
      border: 1px solid $red;
      width: 10px;
      height: 10px;
      display: inline-block;
      background: $red;
      border-radius: 50%;
      margin-left: 2px;
    }
  }

}


.h1, h1 {
  font-size: 2.25rem;
  margin-bottom: 2.25rem;
  font-family: 'Bebas Neue';
}

.h2, h2 {
  font-size: 1.875rem;
  margin-bottom: 0.875rem;
  font-family: 'Bebas Neue';
}

.h3, h3 {
  font-size: 1.625rem;
  margin-bottom: 0.875rem;
  font-family: 'Bebas Neue';

  &.red-dot {
    &::after {
      width: 7px;
      height: 7px;
    }
  }
}

.h4, h4 {
  font-size: 1.5rem;

  &.red-dot {
    &::after {
      width: 7px;
      height: 7px;
    }
  }
}

.h5, h5 {
  font-size: 1.25rem;

  &.red-dot {
    &::after {
      width: 5px;
      height: 5px;
    }
  }
}

.h6, h6 {
  font-size: 1.125rem;

  &.red-dot {
    &::after {
      width: 5px;
      height: 5px;
    }
  }
}

a {
  color: $red;
  transition: $transition-base;
  outline: none;

  &:hover {
    color: $red;
  }

  &:link, &:hover, &:active, &:focus, &:visited:link {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &:before, &:after {
    transition: $transition-base;
  }
}

p, dl, ol, ul, table {
  margin: 0 0 1.625rem;
}

p {
  &.red-dot {
    &::after {
      content: '';
      border: 1px solid $red;
      width: 5px;
      height: 5px;
      display: inline-block;
      background: $red;
      border-radius: 50%;
      margin-left: 2px;
    }
  }
}

ol, ul {
  padding-left: 1.125rem;
}

:last-child {
  margin-bottom: 0 !important;
}

::-webkit-input-placeholder {
  opacity: 0.7;
}

::-moz-placeholder {
  opacity: 0.7;
}

/* firefox 19+ */
:-ms-input-placeholder {
  opacity: 0.7;
  filter: alpha(opacity=100);
}

/* ie */
input:-moz-placeholder {
  opacity: 0.7;
}

img {
  border: 0;
  max-width: 100%;
  position: relative;
  width: auto \9
;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}

img.alignleft,
img.alignright,
img.aligncenter {
  margin-bottom: 1.25rem !important;
}

img.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5rem;
  margin-top: 4px;
}

img.alignright {
  display: inline;
  float: right;
  margin-left: 1.5rem;
  margin-top: 4px;
}

img.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.text-white * {
  color: #fff;
}

.red-dot,
.black-dot {
  list-style: none;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.red-dot li:before,
.black-dot li:before {
  background: $red;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  left: 0;
  top: 8px;
}

.black-dot li:before {
  background: #000100;
}

.red-dot li,
.black-dot li {
  padding: 0 0 0 15px;
  position: relative;
}

@media (min-width: 768px) {
  .md-noEvent {
    pointer-events: none;
  }
}
