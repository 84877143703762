@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@500&display=swap');
body {
    padding: 0 !important;
}

.sticky {
    margin-top: 113px;
    @media (max-width: 991px) {
        margin-top: 80px;
    }
}


/*** ACCORDION ***/

.accordion {
    .card {
        border: 0;
        padding: 2.5rem 0;
        border-top: 1px solid #b7b7b7;
        border-bottom: 0;
        &:first-child {
            border-top: 0;
        }
        @media (max-width: 767px) {
            padding: 1rem 0;
        }
        .card-header {
            padding: 0;
            background: none;
            border: 0;
            margin-bottom: 0;
            position: relative;
            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                width: 63px;
                height: 63px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 60%;
                background-color: $red;
                border-radius: 50%;
                float: left;
                position: absolute;
                left: 0;
                @include Valign();
                @media (max-width: 767px) {
                    width: 46px;
                    height: 46px;
                }
            }
            h5 {
                padding-left: 88px;
                position: relative;
                .btn-link {
                    color: #323232;
                    font-size: 1.625rem;
                    font-weight: 600;
                    padding: 0;
                    display: block;
                    border-bottom: 1px solid #b7b7b7;
                    width: 100%;
                    line-height: 1.2;
                    min-height: 75px;
                    padding-right: 80px;
                    text-align: left;
                    text-decoration: none;
                    background-color: transparent !important;
                    border: 0 !important;
                    &:after {
                        content: '';
                        display: inline-block;
                        vertical-align: middle;
                        width: 23px;
                        height: 23px;
                        background-image: url(../images/minus-red.png);
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        background-size: contain;
                        position: absolute;
                        right: 20px;
                        @include Valign();
                        z-index: 9;
                    }
                    &.collapsed {
                        border: 0;
                        color: $gray-800;
                        &:after {
                            background-image: url(../images/plus-red.png);
                        }
                    }
                    &:hover {
                        text-decoration: none;
                        color: $red;
                    }
                }
                @media (max-width: 767px) {
                    padding-left: 60px;
                    .btn-link {
                        padding-right: 40px;
                        &:after {
                            width: 18px;
                            height: 18px;
                            right: 5px;
                        }
                    }
                }
            }
        } // end of .card-header
        .card-body {
            padding-left: 87px;
            padding-right: 0;
            @media (max-width: 767px) {
                padding-left: 0;
            }
        }
    }
} // end of .accordion
.sub-accordion {
    margin-top: -1.25rem;
    @media (max-width: 767px) {
        margin-top: 1px;
    }
    .card {
        padding: 0;
        &:first-child {
            border-top: 1px solid #b7b7b7;
        }
        .card-header {
            margin-bottom: 0;
            &:before {
                display: none;
            }
            h5 {
                padding-left: 0;
                .btn-link {
                    font-size: 1rem;
                    font-weight: 700;
                    border-bottom: 0;
                    background-color: transparent !important;
                    border: 0 !important;
                    line-height: 1.5;
                    height: auto;
                    padding: 25px 80px 25px 0;
                    @media (max-width: 767px) {
                        padding: 20px 60px 20px 0;
                    }
                    &:after {
                        content: '';
                        display: inline-block;
                        vertical-align: middle;
                        width: 27px;
                        height: 27px;
                        background-image: url(../images/arrow-up-grey.png);
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        background-size: contain;
                        position: absolute;
                        @include Valign();
                        right: 17px;
                        z-index: 9;
                    }
                    &.collapsed {
                        border: 0;
                        &:after {
                            background-image: url(../images/arrow-down-grey.png);
                        }
                    }
                }
                @media (max-width: 767px) {
                    .btn-link {
                        padding-right: 40px;
                        &:after {
                            width: 20px;
                            height: 20px;
                            right: 5px;
                        }
                    }
                }
            }
        }
        .card-body {
            padding-top: 0;
            padding-left: 0;
            padding-right: 80px;
            margin-bottom: 1.875rem;
            @media (max-width: 767px) {
                padding-right: 60px;
                a.btn-primary {
                    width: 200px;
                    line-height: 1.33;
                    padding: 1rem;
                }
            }
        }
    }
} // end of .sub-accordion

/*** OWL CAROUSEL ***/

.home-banner {
    .owl-carousel {
        .owl-nav {
            button {
                background-color: rgba(0, 0, 0, .6)!important;
            }
        }
    }
    ;
}

.owl-carousel {
    position: relative;
    .owl-nav {
        z-index: 1;
        position: absolute;
        left: 0;
        top: calc(50% - 58px);
        margin: 0;
        width: 100%;
        button {
            position: relative;
            margin: 0 !important;
            width: 50px;
            height: 90px;
            border-radius: 0!important;
            span {
                font-size: 0;
            }
            &:hover,
            &:focus {
                background-color: hsla(0, 0%, 100%, .6)!important;
                outline: none;
            }
            &:hover:before {
                color: $red;
            }
            &:before {
                font-family: FontAwesome;
                font-size: 60px;
                line-height: 48px;
                text-indent: 0;
                color: #ccc;
                transition: $transition-base;
            }
            &.owl-prev {
                float: left;
                &:before {
                    content: "\f104";
                }
            }
            &.owl-next {
                float: right;
                &:before {
                    content: "\f105";
                }
            }
        }
    }
    .owl-dots {
        position: absolute;
        bottom: 0;
        left: 25%;
        right: 25%;
        .owl-dot {
            span {
                background-color: #d9d9d9;
                margin: 5px 4px;
                width: 8px;
                height: 8px;
            }
            &.active {
                span {
                    background-color: #010101;
                }
            }
        }
    }
    &.red-arrow {
        .owl-nav button {
            &:before {
                font-size: 44px;
                font-weight: 700;
                color: $red;
            }
            &:hover:before {
                color: $black;
            }
        }
    }
} // end of .owl-carousel
//slick-carousel
.slick-slider {
    img {
        margin: 0 auto;
    }
    .bl-item {
        padding: 2em 1em;
        vertical-align: middle;
    }
    .slick-slide {
        padding: 0 4rem;
    }
}

.bl-nav {
    position: relative;
    bottom: 50%;
    span {
        font-size: 0;
        &:before {
            font-family: FontAwesome;
            font-size: 60px;
            line-height: 48px;
            text-indent: 0;
            color: #ccc;
            transition: $transition-base;
            cursor: pointer;
            position: absolute;
        }
        &:hover:before {
            color: $red;
        }
    }
    .bl-prev {
        &:before {
            content: "\f104";
            padding-left: 1rem;
        }
    }
    .bl-next {
        &:before {
            content: "\f105";
            right: 0;
            padding-right: 1rem;
        }
    }
}

//
.swiper-buttons {
    >div {
        background: none !important;
        transform: none !important;
        opacity: 1 !important;
        visibility: visible;
        &:before {
            font-family: FontAwesome;
            font-size: 60px;
            line-height: 48px;
            text-indent: 0;
            color: #ccc;
            transition: $transition-base;
        }
        &:hover:before {
            color: $red;
        }
        &.swiper-button-prev {
            &:before {
                content: "\f104";
            }
        }
        &.swiper-button-next {
            &:before {
                content: "\f105";
            }
        }
    }
}

.video-modal {
    padding: 0 !important;
    .modal-content {
        height: auto;
        background: transparent;
        border: none;
        text-align: center;
        .modal-body {
            padding: 0;
            border-radius: 0;
            width: 100%;
            z-index: 99999;
            video {
                width: 100%;
            }
            .youtube-container {
                position: relative;
                height: 0;
                padding-bottom: 56.25%;
                padding-top: 30px;
                overflow: hidden;
                iframe,
                object,
                embed {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}


/*** PAGINATION ***/

.pagination {
    li.page-item {
        a.page-link {
            padding: 10px 12px;
            font-size: 14px;
            color: #000;
            border-color: $gray;
            text-transform: uppercase;
            span {
                border-color: $gray;
                //@include border-radius(0);
            }
            &:hover {
                color: $red;
            }
        }
        &:first-child,
        &:last-child,
        &.active {
            a.page-link {
                background-color: $red;
                color: #fff;
                border-color: $gray;
            }
        }
        &.disabled {
            a.page-link {
                color: #e8e8e8;
            }
        }
    }
}


/** Custom CSS **/

#midas-benefits-section {
    background-color: #373a36;
    .container {
        max-width: 1255px;
        padding: 0px;
    }
}

.hidden-desktop {
    display: none;
}

.hidden-mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .hidden-mobile {
        display: none;
    }
    .hidden-desktop {
        background-color:#373a36;
        display: none;
    }
}

@media screen and (min-width: 992px) {
    .hidden-mobile {
        display: flex;
    }
    .hidden-desktop {
        display: none;
    }
}
