.card-article.card-promotions{
    text-align: center;
    p,h4{
        text-align: left;
    }
    a{
        color: white;
        &:hover{
            color:#323232;
        }
    }
}
.archive-pagination {
    .filter-pagination {
        justify-content: center !important;
        display: inline-block !important;
    }
}
.js-bg-image.page-banner img {
    width: 100%;
    height: auto;
}