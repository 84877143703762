// Search form
.search-form {
  @extend .form-inline;
}

.search-form label {
  font-weight: normal;
  @extend .form-group;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.gform_ajax_spinner {
  position: absolute;
}

.contact-form-style {
  .gform_ajax_spinner {
    right: 40px;
    margin-top: 15px;
  }
}

// customized form fields
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
select,
textarea,
div.gform_wrapper input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]):not(.chosen-search-input) {
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid $gray-400;
  color: $gray-700;
  display: inline-block;
  font-family: inherit;
  font-weight: 500;
  font-size: 0.875rem !important;
  margin: 0;
  max-width: 100%;
  padding: 7px 10px !important;
  @include border-radius(5px);
}

input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
select {
  height: 40px;
  width: 100%;
  outline: none;
}

input[type="password"] {
  letter-spacing: 0.3em;
}

input[type="text"]:disabled {
  background-color: #eee !important;
  cursor: default;
  border: 1px solid #aaa !important;
}

input::-webkit-input-placeholder /* Chrome/Opera/Safari */
,
input::-moz-placeholder /* Firefox 19+ */
,
input:-ms-input-placeholder /* IE 10+ */
,
input:-moz-placeholder /* Firefox 18- */
{
  font-size: 0.875rem;
  font-weight: 500;
  color: #c0bfbf;
  background: #c0bfbf;
}

textarea,
.gform_wrapper textarea.textarea {
  height: 200px;
  width: 100%;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgba(51, 51, 51, .6)"></polygon></svg>');
  background-size: 9px 5px;
  background-position: right .5rem center;
  background-repeat: no-repeat;
  padding: 0 1rem 0 5px;
  cursor: pointer;
}

.cta-title {
  font-size: 2.2rem;
  margin-bottom: 2.2rem;
  font-weight: 600;
}

//#field_2_3, #field_4_3 {
//    margin-top: 2rem !important;
//}

#field_2_3, #field_4_3, .gchoice_2_3_1, .gchoice_4_3_1 {
  width: 100% !important;
}

#choice_2_3_1, #choice_4_3_1 {
  height: 20px !important;
  width: 20px !important;
  display: inline;
  vertical-align: sub;
  cursor: pointer;
}

div.gform_wrapper {
  .gform_body {
    .gform_fields {
      .gfield {
        margin: 1rem 0;

        .gfield_label {
          font-size: 1rem;
          font-weight: 600;
          color: $gray-800;
          margin-bottom: 3px;

          .gfield_required {
            color: $red;
          }
        }

        .ginput_container {
          margin: 0 !important;
        }
      }
    }
  }

  .gform_footer {
    text-align: right;
    @media (max-width: 767px) {
      text-align: left;
    }

    input.gform_button {
      max-width: 148px;
      width: 100%;
    }
  }

  &.gform_validation_error {
    .validation_error {
      color: $red;
      border-color: $red;
      display: none;
    }

    .gfield_error {
      background: none !important;
      border: 0 !important;
      margin-top: 0;

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      select,
      textarea {
        border-color: $red !important;
      }

      .validation_message {
        font-size: 0.875rem;
        font-weight: 600;
        color: $red;
        padding-top: 5px;
        position: relative;
      }

      .gfield_label {
        margin-top: 0 !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .gform_wrapper.gform_validation_error {
    .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.gchoice_select_all, .gform_wrapper ul.gfield_checkbox li input[type=checkbox]:checked + label, .gform_wrapper ul.gfield_radio li input[type=radio]:checked + label {
  font-weight: 400 !important;
}

.search-service-online {
  .chosen-container-single {
    .chosen-search-input::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    .chosen-single {
      height: auto;
      border-radius: 0;
      background: white;
      box-shadow: none;
      padding: .5px 0;
      border: .5px solid #a1a1a1;
      border-right: .5px solid transparent;
      @media (max-width: 991px) {
        border-right: .5px solid #a1a1a1;
        border-bottom: .5px solid transparent;
      }

      span {
        font-size: 23px;
        line-height: 4rem;
        font-weight: 500;
        padding-left: 1.5rem;
      }

      &::after {
        content: "\f0d7";
        font-family: FontAwesome;
        position: absolute;
        width: 25px;
        height: 25px;
        font-size: 17px;
        line-height: 1.6rem;
        top: calc(50% - 12.5px);
        right: 10px;
        background: $red;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        @media (max-width: 1199px) {
          padding-top: 1px;
        }
      }
    }

    .content-results {
      padding: 1rem;
    }

    .chosen-search {
      padding: .5rem;
    }

    .chosen-drop {
      border-radius: 0;
      width: calc(100% + 1px);
      border-top: .5px solid #fff;
      @media (max-width: 991px) {
        width: 100%;
        border-top: none;
      }
    }
  }

  .chosen-container-active.chosen-with-drop {
    .chosen-single {
      background-image: none;
      box-shadow: none;
      border-bottom: .5px solid #fff;
      border: .5px solid #a1a1a1;
      border-right: .5px solid transparent;
      @media (max-width: 991px) {
        border-right: .5px solid #a1a1a1;
        border-bottom: .5px solid transparent;
      }
    }
  }

  .chosen-container {
    max-width: 100%;
    @media (max-width: 991px) {
      width: 100% !important;
    }

    .chosen-results {
      padding: 0 .5rem;
      padding-bottom: 0.5rem;
      margin: 0;

      li.highlighted {
        background-color: $yellow;
        background-image: linear-gradient($yellow 20%, $yellow 90%);
        color: #444;
      }
    }
  }
}

.widget-box.enquiry-form {
  input[type=submit] {
    width: 100%;
    max-width: 100% !important;
  }
}

.gform_wrapper {
  .chosen-container {
    width: 100% !important;
  }

  .gfield_time_hour i {
    margin-top: 0 !important;
    line-height: initial;
  }

  #input_5_4_chosen,
  #input_5_15_chosen,
  #input_5_16_chosen,
  #input_5_17_chosen {
    width: 100% !important;
  }
}

table.ui-datepicker-calendar {
  background-color: $white;
}

.gform_wrapper.gf_browser_ie .gfield_time_ampm {
  padding-top: 0 !important;
}

.gform_wrapper ul.gform_fields li.gfield select {
  margin: 0 !important;
}

.ui-datepicker {
  margin-top: 0 !important;
}

input.chosen-search-input {
  font-size: .7rem !important;
  padding: 4px !important;

  &::-ms-clear {
    padding-right: 18px
  }
}

.select2-container--default {
  width: 100% !important;

  .select2-results__option--highlighted[aria-selected] {
    background: $yellow;
    color: inherit;
  }

  .select2-selection {
    outline: none !important;
    min-height: 40px;
  }

  .select2-results__option {
    font-size: 12px;
  }

  .select2-dropdown.select2-dropdown--below {
    margin-top: -1px;;
  }

  .select2-dropdown.select2-dropdown--above {
    margin-bottom: -1px;;
  }

  .select2-selection__rendered {
    padding: 5px 12px;
    opacity: 0.8;
  }

  .select2-selection__arrow {
    top: 7px !important;
    right: 4px !important;
  }
}

.select2-container--disabled {
  .select2-selection__rendered {
    opacity: 0.5;
  }
}

.home {
  .select2-dropdown {
    @media (min-width: 992px) {
      width: 233px !important;
    }
    @media (min-width: 1200px) {
      width: 296px !important;
    }
  }
}

#booking-service-form {
  .select2-selection {
    border-radius: 0;
    border-right: 0;
    height: 66px;
    @media(max-width: 1199px) {
      height: 62px;
      width: 100%;
    }
    @media(max-width: 991px) {
      border-right: 1px solid #aaa;
      border-bottom: 0;
    }

    .select2-selection__rendered {
      font-size: 23px;
      line-height: 4rem;
      font-weight: 500;
      padding: 0;
      padding-left: 1.5rem;
    }

    .select2-selection__arrow {
      top: 1px !important;
      right: 1px !important;
    }
  }

  span.select2-selection__arrow {
    &:after {
      content: "\f0d7";
      font-family: FontAwesome;
      position: absolute;
      width: 25px;
      height: 25px;
      font-size: 20px;
      line-height: 1.67rem;
      right: 15px;
      top: 18px;
      background: $red;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      z-index: 999;
    }

    b {
      display: none;
    }
  }
}

#auto_listing_year_chosen {
  .no-results {
    @extend .d-none;
  }
}

.large input {
  width: 100% !important;
}

.gform_confirmation_message_14 {
  &.gform_confirmation_message {
    margin-top: 20px;
    text-align: center;
  }
}

div.gform_wrapper {
  #gform_14.signup_form {
    .gform_body {
      padding: 120px 15px 60px;

      .ginput_container {
        input[type=text] {
          padding: 30px 15px !important;
          border-radius: 0 !important;
        }
      }


      .gform_fields {
        .gfield {
          margin: 0;
        }
      }
    }

    .gform_footer {
      text-align: center;
    }
  }
}
