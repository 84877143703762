// Grid system
@include media-breakpoint-up(xl) {
    .container {
        max-width: 1210px;
    }
}

.pt-6 {
    padding-top: 3.438rem !important;
}
.pb-6 {
    padding-bottom: 3.438rem !important;
}
.py-6 {
    padding-top: 3.438rem !important;
    padding-bottom: 3.438rem !important;
}

@include media-breakpoint-up(md) {
    .pt-md-6, .py-md-6 {
        padding-top: 3.438rem !important;
    }
    .pb-md-6 {
        padding-bottom: 3.438rem !important;
    }
}

@include media-breakpoint-up(lg) {
    .pt-lg-6, .py-lg-6 {
        padding-top: 3.438rem !important;
    }
    .pb-lg-6 {
        padding-bottom: 3.438rem !important;
    }
}

/* Grid columns - Vertical align content */
.column-vertical-align .row,
.column-vertical-align.row {
    display: flex;
    align-items: center;
}

/* Grid columns - Equal height */
.column-equal-height .row,
.column-equal-height.row {
    display: flex;
    align-items: stretch;
}
/* Content Panel default settings */
.extra-content,
.extra-content-col-inner {
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

/* Custom width container */
.container-custom-width {
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
}

/* Use Divup plugin to create boostrap grid in the WP editor */
.divup-wrap.row .div-1 {
	display: none;
}

/* More support for Bootstrap Grid */
.align-items-stretch .extra-content-col-inner {
    height: 100%;
}
