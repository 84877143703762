/* -----------------------------------------------------------------------------

    MIXINS

----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
    Mixin Helper
----------------------------------------------------------------------------- */

/* transition ease */
@mixin transition( $attr, $secs ) {
	-webkit-transition: $attr $secs ease-in-out;
	-moz-transition: $attr $secs ease-in-out;
	-o-transition: $attr $secs ease-in-out;
	transition: $attr $secs ease-in-out;
}

/* transition */
@mixin transitions( $param ) {
	-webkit-transition: $param;
	-moz-transition: $param;
	-o-transition: $param;
	transition: $param;
}

/* transition rotate */
@mixin transform( $rotate ) {
	-ms-transform: $rotate; /* IE 9 */
	-webkit-transform: $rotate; /* Chrome, Safari, Opera */
	transform: $rotate;
}

/* transition scale */
@mixin transform-scale($scale) {
	-webkit-transform: $scale;
	-moz-transform: $scale;
	-o-transform: $scale;
	transform: $scale;
}

/* border radius */
@mixin border-radius( $radius ) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

/* vertical & horizontal align */
@mixin XYalign() {
	-webkit-transform: translate(-50%, -50%); 
	-moz-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

/* vertical align */
@mixin Valign() {
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

/* horizontal align */
@mixin Halign() {
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

/* border radius */
@mixin flex( $value ) {
	-ms-flex: $value;
	flex: $value;
}

/* display flex center */
@mixin flexCenter() {
	display: flex;
	justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* placeholder */
@mixin placeholder( $color ) {
	&::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $color;
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		color: $color;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		color: $color;
	}
	&:-ms-input-placeholder {
		color: $color;
	}
}

/* text none */
@mixin textnone {
	line-height: 0;
	font-size: 0;
	color: transparent;
}

/* fullscreen background */
@mixin fsBG( $bg ) {
	background: url($bg) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

/* fullscreen cover */
@mixin cover {
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

/* background size */
@mixin background-size( $size ) {
	-webkit-background-size: $size;
	-moz-background-size: $size;
	-o-background-size: $size;
	background-size: $size;
}

/* box shadow */
@mixin box-shadow( $bs ) {
	-webkit-box-shadow: $bs;
	-moz-box-shadow: $bs;
	box-shadow: $bs;
}

/* clearfix*/
@mixin clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
	*zoom: 1;
}

/* gradient generator */
@mixin linearGradient($top, $bottom , $deg, $ldeg  ){
	background: #f9f9f9;
	background: -moz-linear-gradient($deg,  $top 0%, $bottom  100%);
	background: -webkit-linear-gradient($deg,  $top 0%,$bottom  100%);
	background: linear-gradient( $ldeg, $top 0%,$bottom  100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$top', endColorstr='$bottom',GradientType=1 );
}

%bgGradient{
    @include linearGradient(#d0d0d0, #fff, 0, 0);
}


/* List None */
@mixin list-none{
	list-style: none;
	margin: auto;
    padding-left: 0;
    @include clearfix;
}

@mixin toggleIcons{
    &:after{
        content: '';
        width: 18px; 
        height: 30px; 
        background-image: url(../images/plus-red.png); 
        background-repeat: no-repeat; 
        background-position: 50% 50%; 
        background-size: contain; 
        transition: $transition-base; 
        position: absolute;
        right: 20px;
        @include Valign();
    }
    &.active {
        &:after {
            background-image: url(../images/minus-red.png); 
        }
    }
}

@mixin accordionIcons{
    &:after {
        content: '';
        display: inline-block;
        vertical-align: middle; 
        width: 27px; 
        height: 27px; 
        background-image: url(../images/svg/arrow-down-grey.svg); 
        background-repeat: no-repeat; 
        background-position: 50% 50%; 
        background-size: contain; 
        position: absolute;
        @include Valign();
        right: 17px; 
        z-index: 9; 
    }

    &.active {
        &:after {
        background-image: url(../images/svg/arrow-up-grey.svg); 
        }
    }
}

