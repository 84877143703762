.acf-map {
    width: 100%;
    height: 90vh;
    border: #ccc solid 1px;
    margin:0;
}

/* fixes potential theme css conflict */
.acf-map img {
    max-width: inherit !important;
}
