
.franchising-red-circle{
    text-align: center;
    padding-top: 3rem;
    @include list-none;
    li{
        display: inline-block;
        padding: 0 35px;
        width: 24%;
        vertical-align: top;
        a{
            display: block;
            height: 167px;
            width: 167px;
            background-color: $red;
            color: $white;
            font-size: 1.563rem;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
            overflow: hidden;
            @include border-radius( 50% );
            @include flexCenter();
            margin: 0 auto;
            margin-bottom: 1rem;
            pointer-events: none;
        }
        strong,span{
            display: block;
        }
    }
}

.franchising-carousel{
    padding-top: 3rem;
    h3{
        text-align: center;
    }
    .owl-carousel{
        padding: 2.5rem 2.5rem 3.75rem;
        .fc-content{
            padding: 1.25rem;
            @extend %bgGradient;
            h6{}
            p{
                font-size: 14px;
                &:last-child{
                    font-size: 1rem;
                    font-weight: 500;
                    font-style: italic;
                }
            }
        }
        .fc-photo{
            img{
                height: auto;
            }
        }
    }
    a.btn-primary{
        min-width: 275px;
        padding: 4px 1.25rem 0;
        line-height: 50px;
    }
}

@media (max-width: 1199px) {
    .franchising-red-circle{
        padding-top: 2rem;
        li {
            padding: 0 20px;
        }
    } 
    .franchising-carousel{
        padding-top: 1rem;
        .owl-carousel{
            padding: 1.5rem 2.5rem 2.75rem;
        }
    }
}

@media (max-width: 992px) {
    .franchising-red-circle{
        li {
            padding: 0 15px;
            a{
                height: 130px;
                width: 130px;
                img{
                    height: 80px;
                    width: 80px;  
                }
            }
        }
    } 
}

@media (max-width: 767px) {
    body.page-template-template-franchise{
        .main-content{
            padding-bottom: 1rem;
        }
    }
    .franchising-red-circle{
        padding-top: 0.25rem;
        li {
            padding: 0 0 30px;
            width: 50%;
            float: left;
            &:nth-child(2n){
                padding-left: 12px;
            }
        }
    } 

    .franchising-carousel{
        padding-top: 0;
        .owl-carousel{
            padding: .75rem 0 2rem;
            .fc-content {
                padding: 1.25rem 2.25rem;
            }
            .owl-nav{
                top: calc(72% - 58px);
            }
        }
    }
}
