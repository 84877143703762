
.widget-box {
    max-width: 320px;
    width: 100%;
    margin-bottom: 1.5rem;

    h5 {
        padding: 20px 15px;
        background: $yellow;
        margin-bottom: 0;

        &.with-icon {
            background: $yellow url(../images/midas-logo-pin-marker.png) no-repeat right 14px center;
            @include background-size(60px auto);
        }
    }

    .widget-box-inner {
        background: $gray-100;
        padding: 1.5rem 0.938rem;
        color: #323232;

        p {
            font-size: 0.938rem;
            margin-bottom: 18px;

            .icon-direction {
                &:before {
                    background-image: url(../images/midas-store-directions-icon.svg);
                }
            }
        }

        ul {
            padding-left: 2rem;
            li {
                font-size: 0.938rem;
            }
        }

        table {
            width: 100%;

            tr {
                th, td {
                    width: 50%;

                    &:last-child {
                        text-align: right;
                    }
                }

                th {
                    font-size: 15px;
                    font-weight: 700;

                    &.th-additional-details-toggle {
                        cursor: pointer;
                    }
                }

                td {
                    font-size: 13px;
                    font-weight: 400;
                }

                &.th-additional-details {
                    display: none;
                }

                .plus-sign {
                    color: $red;
                }

                .open, .closed {
                    background-color: #00b500;
                    color: $white;
                    font-size: 14px;
                    font-weight: 700;
                    display: inline-block;
                    padding: 0 4px;
                    text-transform: uppercase;
                    @include border-radius(4px);
                }

                .open {
                    background-color: #00b500;
                }

                .closed {
                    background-color: $red;
                }

                &.current-day {
                    td {
                        font-weight: 700;
                    }
                }
            }
        }

        a.btn-primary {
            width: 100%;
            text-align: center;
            font-size: 1.188rem !important;
            line-height: 1.15 !important;
            padding: 1.125rem 1rem !important;

            &.btn-semi {
                font-size: 1rem;
            }
        }
    }

    a {
        img {
            height: auto;
        }
    }

    .other-links {
        background: $white;
        padding: 15px;
        margin-top: 15px;

        ul {
            li:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}

@media (max-width: 767px) {
    .sidebar {
        padding-top: 30px;

        .widget-box {
            margin-left: auto;
            margin-right: auto;
        }

    }
}