.home-subheading {
  text-align: center;
  color: #e41e25;
  font-weight: 700;
}

.home-banner {
  overflow: hidden;
  position: relative;
  height: 411px;
  @include cover;

  .owl-carousel {
    .slide-item {
      display: block;
      width: 100%;
      height: 411px;
      position: relative;
      @include cover;
      text-decoration: none !important;
    }

    .owl-item {
      .owl-lazy:not([src]), .owl-lazy[src^=""] {
        max-height: 411px;
      }
    }

    .slide-item-link {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  .HB-overlay {
    max-width: 1210px;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    z-index: 9;
    @include Halign();
  }

  .hb-text {
    max-width: 320px;
    width: 100%;
    padding-top: 24px;

    h1 {
      font-size: 4.063rem;
      font-weight: 700;
      color: $yellow;
      text-transform: uppercase;
      line-height: .925;
      letter-spacing: 1px;
      margin-bottom: 7px;
    }

    h4 {
      color: #fff;
      line-height: 1.4;
      letter-spacing: 1px;
    }
  }

  .hb-store-infos {
    background-color: $black;
    text-align: center;
    padding: 1rem 2.188rem;
    float: right;
    max-width: 330px;
    width: 100%;
    height: 411px;
    z-index: 9;
    @include flexCenter();
    position: relative;
    margin-top: -411px;
    left: 57px;
    overflow-y: auto;
    @media (max-width: 1299px) {
      max-width: 290px;
      padding: 1rem;
      position: absolute;
      margin-top: 0;
      left: auto;
      top: 0;
      right: 20px;
    }

    p {
      font-size: 0.938rem;
      font-weight: 500;
      line-height: 1.47;
    }

    h3 {
      color: $yellow;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0;
    }

    a {
      color: $yellow;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  @media (max-width: 1199px) {
    height: 360px;
    .hb-text {
      max-width: 260px;

      h1 {
        font-size: 3.5rem;
      }

      h4 {
        font-size: 1.25rem;
      }
    }
    .hb-store-infos {
      height: 360px;
    }
    .owl-carousel {
      .slide-item {
        height: 360px;
      }

      .owl-item {
        .owl-lazy:not([src]), .owl-lazy[src^=""] {
          max-height: 360px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    height: 300px;
    .hb-text {
      max-width: 230px;

      h1 {
        font-size: 2.675rem;
      }

      h4 {
        font-size: 1rem;
        font-weight: 500;
      }
    }
    .hb-store-infos {
      height: 300px;

      p {
        margin-bottom: 10px;
      }
    }
    .owl-carousel {
      .slide-item {
        height: 300px;  
      }

      .owl-item {
        .owl-lazy:not([src]), .owl-lazy[src^=""] {
          max-height: 300px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    height: auto;
    .owl-carousel {
      .slide-item {
        height: 300px;
      }

      .owl-item {
        .owl-lazy:not([src]), .owl-lazy[src^=""] {
          max-height: 200px;
          opacity: 1;
          background-position-x: 40%;
        }
      }
    }
    .hb-text {
      max-width: 180px;
      height: 220px;
      padding-top: 18px;

      h1 {
        font-size: 2.5rem;
      }

      h4 {
        font-size: 13px;
      }
    }
  }
}


body.home .main-content {
  background-color: #fafafa;
  padding: 2rem 0 3.25rem;
}

.search-service-online {
  padding: 2.5rem 0 4rem;

  .group-fields {
    @include clearfix;

    > div {
      float: left;
      width: 25%;

      &.button-field {
        input {
          font-size: 1.438rem;
          font-weight: 500;
          line-height: 60px;
          padding: 3px 10px;
          width: 100%;
          @media (max-width: 1199px) {
            font-size: 1.125rem;
            line-height: 56px;
          }
        }
      }
    }
  }

  .jqTransformSelectWrapper {
    border: 2px solid #d4d4d4;
    border-right: 0;

    div {
      span {
        font-size: 23px;
        font-weight: 500;
        color: #000;
        padding-left: 30px;
        line-height: 62px;
        @media (max-width: 1199px) {
          font-size: 18px;
          padding-left: 14px;
          line-height: 55px;
        }
      }

      a.jqTransformSelectOpen {
        width: 48px;

        &:before {
          content: '';
          background: url(../images/icons/red-circle-arrow-down@2x.png) no-repeat 50% 50%;
          @include background-size(25px auto);
        }
      }
    }
  }
}

.midas-benefits {
  @media (min-width: 991px) {
    padding: 10px 0px;
  }
  a {
    color:unset;

    &:hover {
        color:unset;
    }
  }

  div, a {
    padding-left: 1.25rem;
    padding-top: 1.25rem;
    @media (min-width: 991px) {
      p {
        max-width: 260px;
      }
      &:last-child p {
        max-width: 294px;
      }
      padding: 0px;
      text-align: center;
    }

    p {
      font-family: 'bebas neue';
      font-size: 22px;
      color: #ffffff;
      position: relative;
      padding-left: 70px;
      height: 55px;
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      @media (max-width: 767px) {
        align-items: start;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 55px;
        height: 55px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 90%;
        @include border-radius(50%);
      }
      &:hover {
        color:#ffff00;
      }
    }
  }

  .wrapper {
    display: flex;
    min-width: 295px;
    padding: 0px !important;
    justify-content: space-around;

    p {
      @media (max-width: 767px) {
        padding-right: 2.5rem;
      }
    }
  }
}

.home-extra-content-01 {
  h1 {
    font-weight: 400;
    margin-bottom: 1.25rem;
  }

  p {
    font-size: 1.125rem;
  }

  .midas-portals {
    @include list-none;
    @include clearfix;
    padding: 1.5rem 0 3.5rem;

    li {
      float: left;
      width: 33.3333%;
      position: relative;

      img {
        max-width: 100%;
        height: auto;
      }

      .btn-primary {
        position: absolute;
        left: 14px;
        bottom: 12px;
        line-height: 64px;
        padding: 0 1.5rem;
        font-size: 1.438rem;
        font-weight: 600;
        letter-spacing: 1.9px;

        &:hover {
          background-color: #000;
          color: #fff;
        }

        @media (max-width: 991px) {
          line-height: 40px;
          padding: 0 1.25rem;
          font-size: 1rem;
          letter-spacing: 1px;
        }
        @media (max-width: 767px) {
          line-height: 54px;
          font-size: 1.125rem;
        }
      }

      .btn-play {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.31);
        @include flexCenter();

        img {
          cursor: pointer;
        }
      }
    }

    @media (max-width: 767px) {
      max-width: 390px;
      width: 100%;
      margin: 0 auto;
      li {
        width: 100%;
        margin-bottom: 13px;
      }
    }
  }

  @media (min-width: 992px) {
    .row-02 {
      h3, h4 {
        padding-left: 35px;
      }
    }
  }

  .row-03 {
    padding-top: 40px;
  }
}

.home-extra-content-02 {

  padding: 60px 40px;

  .section-content {
    padding: 25px 0;
  }

  &.booking-section {
    background: $yellow;

    p {
      margin-bottom: 0;
    }

    .booking_cta {
      padding-top: 30px;

      .btn-primary {
        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }
  }

  &.newsletter-section {
    background: #ececec;

    h2 {
      color: $red;
    }

    .gform_wrapper {
      input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]):not(.chosen-search-input) {
        border-radius: 0;
      }
    }

    div.gform_wrapper .gform_footer {
      text-align: center;
    }

    @media (min-width: 768px) {
      .gform_wrapper {
        input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]):not(.chosen-search-input) {

          padding: 23px 15px !important;
        }

        &.gform_legacy_markup_wrapper {
          margin: 16px 30px;
        }

        #field_2_3 {
          margin: 0 !important;
        }

        input[type=submit] {
          line-height: 3em;
        }

        .gform_footer {
          display: inline-block;
          padding: 15px 0;
          width: 20%;
          margin: 0;
          text-align: left;
        }

        .gform_body {
          display: inline-block;
          float:left;
          width: 80%;

        }
      }
    }


  }


  &.faq-section {
    button.owl-prev {
      left: -15%;
    }

    button.owl-next {
      right: -15%;
    }
  }

  &.testimonials-section {
    background: $yellow;

    h2 {
      color: $red;
    }

    button.owl-prev {
      left: -15%;
    }

    button.owl-next {
      right: -15%;
    }

    span {
      &.customer_name {
        font-weight: bold;
        color: $red;
      }

      &.store_name {
        font-style: italic;
        color: $red;
      }
    }
  }

  &.featured-brands-section {
    button.owl-prev {
      left: -10%;
    }

    button.owl-next {
      right: -10%;
    }

    .featured-brands-slider {
      .brand-logo-item {
        display: flex;
        height: 100px; /* Or whatever */

        .brand-log-img {
          width: 80px; /* Or whatever */
          height: 80px; /* Or whatever */
          margin: auto; /* Magic! */
        }
      }

    }
  }

  &.payment-section {
    background-color: #ececec;
    padding: 30px 20px;

    h2 {
      line-height: 4.5rem;
      color: $red;
      font-weight: bold;
    }

    img {
      filter: gray; /* IE6-9 */
      -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
      filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    }

  }
}

.home-carousel,
.home-slider,
.slick-slider {
  position: relative;
  height: 260px;
  overflow: hidden;
  @extend %bgGradient;

  .owl-carousel {
    height: 100%;
  }
}

.home-slider {
  .owl-item {
    img {
      display: none;
    }

    &.active {
      img {
        display: block;
      }
    }
  }

  padding: 1.25rem 0.938rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 20px;
    right: 12px;
    width: 240px;
    height: 100px;
    //background: url(../images/red-car.png) no-repeat right bottom;
    @include background-size(237px auto);
  }

  .hs-item {
    width: 100%;
    padding-left: 80px;
    padding-bottom: 20px;
    position: relative;
    z-index: 9;

    p {
      font-size: 1.125rem;
      line-height: 1.39;
      color: $gray-800;

      strong {
        font-weight: 600;
        font-style: italic;
      }
    }

    img {
      position: absolute;
      right: 0;
      bottom: 20px;
      max-width: 235px;
      height: auto;
      transition-duration: 1s;
    }

    .hs-author {
      max-width: 200px;
    }
  }

  @media (max-width: 1199px) {
    &:after {
      @include background-size(200px auto);
    }
    .hs-item {
      padding-left: 40px;

      img {
        right: calc(100% - (100% + 70px));
      }
    }
  }
  @media (max-width: 991px) {
    .hs-item {
      p br {
        display: none;
      }
    }
  }
}

.home-carousel {
  padding: 30px 45px;
}

.swiper-container {
  width: 100%;
  height: auto;
  transition: opacity .6s ease;

  &.loading {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }
}

.swiper-slide {
  height: 80px;
  overflow: hidden;
  @include flexCenter();
}

[class^="swiper-button-"] {
  width: 44px;
  opacity: 0;
  visibility: hidden;
  //@extend %transition_all_03s;
}

.swiper-button-prev {
  transform: translateX(50px);
}

.swiper-button-next {
  transform: translateX(-50px);
}

.swiper-container-horizontal {
  > .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin: 0 9px;
      position: relative;
      width: 12px;
      height: 12px;
      background-color: #fff;
      opacity: .4;
      //@extend %transition_all_03s;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 18px;
        height: 18px;
        transform: translate(-50%, -50%);
        border: 0px solid #fff;
        border-radius: 50%;
        // @extend %transition_all_03s;
      }

      &:hover,
      &.swiper-pagination-bullet-active {
        opacity: 1;
      }

      &.swiper-pagination-bullet-active {
        &::before {
          border-width: 1px;
        }
      }
    }
  }
}


/* Newsletter */
#cta-newsletter {
  background-color: $yellow;

  h1 {
    text-align: center;
    @media (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 0;
    }
  }

  .gfield_description.validation_message {
    position: relative;
  }

  .gform_wrapper {
    .gform_body {
      width: 76%;
      float: left;

      ul {
        li {
          width: 50%;
          float: left;
          clear: none;
          margin: 0;
          padding-right: 25px;
          @media (max-width: 991px) {
            padding-right: 10px;
          }

          input[type="text"] {
            font-size: 1.125rem;
            font-weight: 500;
            width: 100%;
            height: 63px;
            background-color: $white;
            border: 0;
            padding: 1rem 1.875rem;
            letter-spacing: 0.7px;
            text-transform: none;
            @include border-radius(0);
            @include box-shadow(inset 0 0.5px 5px 0 rgba(0, 0, 0, 0.19));
          }

          &.jqTransform-field {
            width: 100%;
            padding-top: 25px;

            ul li {
              width: 100%;
            }
          }

          .gfield_checkbox {
            label {
              font-size: 1.313rem;
              margin-left: 1.25rem;
            }
          }

          &.gfield_error {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0 !important;

            input[type="text"] {
              border: 1px solid #790000;
            }

            max-width: 100% !important;
          }

        }
      }

      .gfield_label {
        display: none;
      }
    }

    .gform_footer {
      width: 24%;
      float: left;
      clear: none;
      margin: 0;
      padding: 0;

      input[type="submit"] {
        max-width: 100%;
        width: 100%;
        height: 63px;
        font-size: 1.125rem;
        letter-spacing: 0.7px;
        margin: 0;

        &:hover {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 40px 0 20px;
    .gform_wrapper {
      position: relative;

      %newsgform {
        width: 100%;
        float: none;
      }

      .gform_body {
        @extend %newsgform;

        ul {
          li {
            @extend %newsgform;
            padding: 0 0 20px;

            &.jqTransform-field {
              padding: 60px 0 0;
            }

            input[type="text"] {
              height: 55px;
            }

            .gfield_checkbox label {
              font-size: 16px;
              margin-left: 8px;
            }
          }
        }
      }

      .gform_footer {
        @extend %newsgform;
        position: absolute;
        bottom: 28%;
        left: initial;

        input[type="submit"] {
          height: 55px;
        }
      }
    }
  }
}
@media (max-width: 1209px) {
    .midas-benefits {
        .wrapper {
            min-width: auto;
        }
    }
}
@media (max-width: 1199px) {
    #midas-benefits-section .container {
        max-width: 915px;
    }
}

@media (max-width: 991px) {
  .search-service-online {
    padding: 0.5rem 0 3rem;

    .group-fields > div {
      float: none;
      width: 100%;

      &.button-field {
        input {
          font-size: 1.25rem;
          line-height: 50px;
          letter-spacing: 2px;
        }
      }
    }

    .jqTransformSelectWrapper {
      border-right: 2px solid #d4d4d4;
      border-bottom: 0;
    }
  }

  .home-extra-content-01 {
    .row-02 {
      h3 {
        font-size: 1.5rem;
      }

      h4 {
        font-size: 1.25rem;
      }
    }
  }
  .home-slider {
    &:after {
      display: none;
    }

    .hs-item {
      padding: 0 40px;

      img {
        right: calc(100% - (100% + 150px));
        bottom: 0;
      }
    }
  }
  .midas-benefits {
    .wrapper {
        min-width: 295px;
        padding: 1.25rem 0px!important;
    }
  }
}
#midas-benefits-carousel-menu, .midas-benefits-carousel-menu {
    display: none;
}
@media screen and (max-width: 991px) { 
    .midas-benefits-carousel-menu {
        text-align:center;
        display: block;
        
        .owl-carousel.owl-loaded {
            display: block;
            background: #373a36;
        }
        p {
            line-height: 2;
            display: inline-block;
            vertical-align: middle;
            font-family: 'Bebas Neue';
            color: #ffffff;
            padding-left: 30px;
            padding-top: 4px;
            padding-bottom: 4px;
            &:hover {
                color : #ffff00;
            }
            &:before {
                content: "";
                display: inherit;
                position: absolute;
                left: 0;
                margin: 2px;
                width: 26px;
                height: 26px;
            }
        }
        a {
            color : #ffff00;
            text-decoration: none;
            &:hover {
                color : #ffff00;
            }
        }
        .item {
            display: inline;
            justify-content: center;
        }
    }
}

@media (max-width: 767px) {
  .home-banner {
    .col-lg-12 {
      padding: 0;
    }

    .HB-overlay {
      top: initial;
      bottom: 0;
    }

    .hb-store-infos {
      max-width: 100%;
      height: auto;
      padding: 3px 15px;
      margin: 0;
      text-align: left;
      display: block;
      position: relative;
      top: 0;
      min-height: 29px;
      right: 0;
      overflow-y: unset;
      width: 100%;

      &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
      }

      &:before {
        left: -100%;
      }

      &:after {
        right: -100%;
      }

      p {
        display: none;

        &:first-child {
          display: inline;
          font-size: 10px;
          margin: 0;

          span.icon-marker:before {
            width: 11px;
            height: 13px;
            margin-right: 6px;
            margin-top: -3px;
          }

          span.d-md-none {
            color: $yellow;
            margin-left: 0;
            font-weight: 600;
          }
        }
      }

      h3 {
        display: inline;
        font-size: 10px;
        vertical-align: middle;
        margin-left: 5px;
        text-transform: uppercase;
      }
    }
  }

  .home-extra-content-01 {
    .row-02 {
      text-align: center;

      h3 {
        font-size: 26px;
        font-weight: 500;
      }

      h4 {
        font-size: 24px;
        font-weight: 500;
      }

      > div:last-child {
        h4 {
          display: none;
        }
      }
    }
  }
  .home-slider {
    padding: 1.5rem .938rem;
    height: auto;
    margin-bottom: 50px !important;

    .hs-item {
      padding: 0 5rem;
      max-width: 100%;

      p {
        font-size: 16px;

        &.strong {
          font-size: 13px;
          line-height: 1.45;
          display: block;
        }
      }

      img {
        right: 0;
      }
    }
  }
  .home-carousel {
    height: auto;
    background: none;
    padding: 0;
  }
  .home-slider {
    .active {
      img {
        display: none !important;
      }
    }

    .hs-item .hs-author {
      max-width: 100%;
    }
  }
  .owl-carousel .owl-nav button:before, .bl-nav span:before {
    font-size: 40px;
    color: $red;
  }
  .slick-slider {
    background: none;

    .slick-slide {
      padding: 0 5rem;
    }
  }
  .extra-content {
    h4, h3 {
      text-align: center;
      padding: 0 !important;
    }
  }
  .home-carousel, .home-slider, .slick-slider {
    height: auto;
  }
  .midas-benefits {
    .wrapper {
        min-width: auto;
        padding: 1rem 2rem !important;
        justify-content: space-between;
    }
    a {
        padding: 0px;
    }
  }
}

@media (max-width: 426px) {
  .home-slider {
    .hs-item {
      padding: 0 3rem;
    }
  }
}