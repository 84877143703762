body.page-contact-us{
    @media (max-width: 1199px) {
        .main-content{
            h3{
                br{
                    display: none;
                }
            }
        }
    }
    .gform_wrapper{
        margin-top: 9px;
        li.gfield{
            textarea{
                height: 280px;
            }
        }
        .gform_footer{
            padding-right: 16px;
            input.gform_button{
                margin: 0;
                line-height: 54px !important;
            }
        }
        @media (max-width: 991px) {
            margin-top: 3px;
            li.gfield{
                width: 100% !important;
                padding: 0 !important;
                textarea{
                    height: 200px;
                }
            }
            .gform_footer{
                padding-right: 0;
            }
        }
    }
}   