// Grays
$gray-100: #f5f5f5;
$gray-200: #d8d8d8;
$gray-300: #ababab;
$gray-400: #9d9d9d;
$gray-600: #868e96;
$gray-700: #4c4c4c;
$gray-800: #323232;
$gray-900: #979797;
$gray-custom: #ccd1d9;
$gray-secondary: #373a36;
// Colors
$black: #000;
$white: #fff;
$blue: #00a8df;
$red: #da291c;
$yellow: #ffeb00;
$green: #28a745;
$cyan: #17a2b8;
$very-dark-blue: #21275a;
$very-soft-cyan: #85d3e3;
$navy: #212857;

// Theme colors
$primary: $red;
$secondary: $yellow;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;
$gray: $gray-custom;

// Fonts
$font-family-base: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-size-base: 16px;


// Transition
$transition-base: all .3s ease-in-out;

// Spacers
$spacer: 1rem;
$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3),
        6: ($spacer * 3.75),
        7: ($spacer * 4.5),
        8: ($spacer * 5.625),
        9: ($spacer * 7.5)
);
$spacer-1: ($spacer * 1.5);
$spacer-2: ($spacer * 3);
$spacer-3: ($spacer * 4.5);
$spacer-4: ($spacer * 5.625);

// Menu
$menu-height: inherit;
