.services-carousel{
    .container{
        max-width: 1280px;
        width: 100%;
    }
    .owl-carousel{
        padding: 0 35px;
    }
    .sc-content{
        padding: 13px 15px 15px;
        @extend %bgGradient;
        h4{
            display: flex;
            align-items: center;
            padding-left: 5px;
            span{
                display: inline-block;
                line-height: 50px;
                height: 50px;
                width: 50px;
                text-align: center;
                background-color: $red; 
                margin-right: 1.25rem;
                @include border-radius( 50% );
                @include flexCenter();
                img{
                    max-width: 32px;
                    width: 100%;
                    height: auto;
                }
            }
        }
        p:last-child{
            @media (min-width: 1200px) {
                padding-left: 5px;
            }
        }
        a.btn-primary{          
            min-width: 46%;
            text-align: center;
            font-size: 1rem;
            line-height: 54px;
            padding: 0 5px;
            margin-bottom: 15px;
            &.more{
                margin-right: 1rem;
            }
            @media (max-width: 1199px) {
                font-size: 0.938rem;
                &.more{
                    margin-right: 0.875rem;
                }
            }
            @media (max-width: 425px) {
                min-width: 100%;
            }
        }
    }
    @media (max-width: 575px) {
        .container{
            max-width: 540px;
        }
        .owl-carousel {
            padding: 0;
            &.red-arrow .owl-nav{
                top: calc(50% - 125px);
                button:before{
                    font-size: 30px;
                }
            } 
        }
        .sc-content{
            padding: 25px 20px 30px;
            h4{
                font-size: 24px;
                margin-bottom: 7px;
                span{
                    display: none;
                }
            }
            p{
                font-size: 16px;
                line-height: 1.4;
                &:last-child{
                    text-align: center;
                }
            }
            a.btn-primary{
                font-size: 16px;
                line-height: 46px;
                min-width: 160px;
                margin: auto;
                &.more{
                    display: none;
                }
            }
        }
    }
}

.services-guarantee {
    background-color: #fdf2dc;
    position: relative;

    /*&:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }*/

    h2 {
        color: $red;
        font-weight: 700;
        padding-bottom: 10px;
    }
}

.bg-gray{
    background-color: #b7b7b7;
    padding: 24px 20px;
    p{
        font-style: italic;
    }
    &.left{
        p{
            font-size: 12px;
            &:first-child{
            font-size: 14px;
            }
        }
    }
    &.right{
        p{
            font-size: 10px;
            &:first-child{
            font-size: 12px;
            }
        }
    }
}

// Services Landing Template
.services-landing-cta {
    background-color: $gray-200;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }

    h1 {
        font-size: 50px;
        font-weight: 700;
        font-style: italic;
    }

    h6 {
        font-weight: 700;
        font-style: italic;
        padding-bottom: 14px;
    }
}

.sc-photo{
    img{
        height: auto;
    }
}

.services-cta{
    text-align: center;
    .left{
       padding-bottom: 1rem;
    }
}