body.page-template-template-make-a-booking {
    .earn-points-box {
        position: relative;
        display: inline-block;

        .btn-primary {
            position: absolute;
            bottom: 12px;
            left: 14px;
            font-size: 22.5px;
            letter-spacing: 1.9px;
            line-height: 64px;
            min-width: 235px;

            &:hover {
                background-color: #000;
                color: #fff;
            }
        }

        img {
            height: auto;
            width: 100%;
        }
    }

    .styled-form {
        padding-top: 4rem;

        form {
            padding-top: 0;
        }

        .form-heading {
            background-color: $yellow;
            padding: 2rem 2rem 1rem;
            border-bottom: 9px solid #e61c28;
            @include border-radius(24px 24px 0 0);

            h2.form-title {
                font-weight: 700;
                color: #000;
            }
        }

        .form-body {
            background-color: #ebebeb;
            padding: 15px 0 0;

            .form-section,
            .styled-form_wrapper {
                border-bottom: none !important;
                overflow-x: hidden;
                padding: 0 45px 0;
                border-bottom: 2px solid #d0d0d0;

                .section-heading {
                    border: none;
                    padding-bottom: 0;
                    margin-bottom: 0 !important;
                    width: calc(100% + 90px) !important;
                    margin-left: -45px !important;
                    padding-left: 2.5em;

                    h2 {
                        color: $red;
                        font-weight: 700;
                        font-size: 1.625rem;

                        span {
                            font-size: 14.5px;
                            font-weight: 500;
                            font-style: italic;
                            color: #000;
                            display: block;
                            padding-top: 5px;
                        }
                    }

                    &:not(:first-child) {
                        border-top: 2px solid #d0d0d0;
                        padding-top: 2rem !important;
                    }
                }

                &.last,
                &:last-child {
                    border-bottom: 0;
                }

                .ginput_container_consent {
                    max-width: 600px;
                    margin: 0 auto !important;

                    .gfield_consent_label {
                        display: inline;
                        font-weight: 500;
                    }

                    input[type=checkbox] {
                        height: 20px;
                        width: 20px;
                        display: inline;
                        vertical-align: text-bottom;
                        margin-right: 10px;
                        cursor: pointer;
                        @media (max-width: 767px) {
                            height: 15px;
                            width: 15px;
                            vertical-align: unset;
                            margin-right: 5px;
                            top: 2px;
                            position: relative;
                        }
                    }
                }

                .gform_footer {
                    text-align: center;
                    padding-bottom: 4rem;

                    input {
                        padding: 10px 0;
                        max-width: 275px;
                        font-size: 19px;
                    }
                }

                @media (max-width: 424px) {
                    padding: 0 1rem 0;
                }
            }

            .gform_wrapper {
                .gform_body .gform_fields .gfield .ginput_container.ginput_container_consent {
                    margin: 0 auto !important;
                }

                .chosen-container .chosen-results li.highlighted {
                    background-color: $yellow;
                    background-image: linear-gradient($yellow 20%, $yellow 90%);
                    color: #444;
                }

                .chosen-container-single {
                    .chosen-single {
                        height: 39px !important;
                        border-radius: 5px !important;
                        background: white !important;

                        span {
                            @media (min-width:768px){
                                padding: 7px 10px !important;
                                font-size: 14px;
                               
                            } 
                            padding-left: 5px !important;
                            color: #757575;
                        }

                        b {
                            @media (min-width:768px){
                            background-position: 0 9px !important;
                            }

                        }
                    }

                    .chosen-drop {
                        margin-top: -3px !important;
                    }
                }
            }

            .styled-form_wrapper {
                .gsection {
                    width: 100%;
                }

                .gfield {
                    display: inline-block;
                    width: 100%;

                    select,
                    input[type=text],
                    input[type=select],
                    input[type=textarea] {
                        width: 100% !important;
                    }

                    .button-field {
                        display: block;
                        position: relative;
                        padding-left: 4rem;

                        &:before {
                            content: "or";
                            font-size: .875rem;
                            font-weight: 700;
                            color: #999;
                            text-transform: uppercase;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        i {
                            font-size: 1.5rem;
                        }

                        button {
                            width: 100%;
                            @media (max-width: 1199px) {
                                font-size: 0.8rem;
                                padding: 0 20px;
                            }
                        }
                    }
                }

                .gform-fields {
                    @include list-none;
                    @include clearfix;
                }

                .one-third {
                    width: 33.33%;
                    padding-left: 2rem;
                    vertical-align: top;
                    @media (max-width: 767px) {
                        padding-left: 0;
                    }
                }

                .two-third {
                    width: 66.67%;
                    padding-left: 2rem;
                    vertical-align: top;
                    @media (max-width: 767px) {
                        padding-left: 0;
                    }
                }

                .clean {
                    margin: 0 !important;
                }
            }
        }

        //end of .form-body
        .form-footer {
            text-align: center;
            padding: 4px 0 50px;

            input[type="submit"] {
                font-size: 19px;
                line-height: 74px;
                letter-spacing: 1.2px;
                min-width: 275px;
            }
        }

        #field_8_28 .validation_message {
            display: none !important;
        }

        .gform_confirmation_message {
            padding: 0 50px 100px 50px
        }
    }

    @media (max-width: 1199px) {
        .earn-points-box {
            .btn-primary {
                font-size: 20px;
                line-height: 54px;
                min-width: 200px;
            }
        }
        .styled-form .form-body .form-fields {
            .field {
                padding-left: 25px;

                &.location-field {
                    .button-field {
                        padding-left: 40px;

                        i {
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 991px) {
        .styled-form .form-body {
            .styled-form_wrapper {
                .one-third {
                    width: 50%;
                }

                .two-third {
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 767px) {
        .earn-points-box {
            padding-top: 30px;
        }
        .styled-form {
            padding-top: 0;

            .form-body {
                .form-section {
                    padding: 25px 30px 5px;
                }

                .styled-form_wrapper {
                    .one-third {
                        width: 100%;
                    }
                }
            }
        }
    }
}
:-ms-input-placeholder{
    color: #757575 !important;
}
.drop-off-time {
    i{
        right: -5px;
        position: relative;
    }
    label {
    margin: 0 auto !important;
    }
}