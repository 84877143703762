.main-footer {
  .footer-inner {
    background: $black;
    padding-top: $spacer-2;
    padding-bottom: $spacer-2;
    @include media-breakpoint-up(md) {
      .col-sm-12 {
        padding-bottom: 20px;
      }
    }
    @media (max-width: 767px) {
      background: $gray-200;
      padding-top: 0;
      padding-bottom: 0;
      text-align: center;

      .col-lg-2 {
        border-bottom: 1px solid #333;
      }
    }
    @media (max-width: 767px) {
      .container {
        max-width: 100%;

        > .row {
          > div:last-child {
            padding-top: 2rem;
            padding-bottom: 2rem;
            background: $black;
          }
        }
      }
    }

    .menu-item {
      ._svg, img._mi {
        height: 0;
        width: 0;
        position: absolute;
        display: none !important;
      }

    }

    @media (min-width: 992px) {
      .container {
        .row {
          > div {
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
          }
        }
      }
    }

    h6 {
      font-size: 1.125rem;
      line-height: 1.25;
      font-weight: 700;
      margin-bottom: 0;

      a {
        color: white;
      }

      @media (max-width: 767px) {
        background: $black;
        text-align: center;
        padding: 1.25rem 0;
        margin-left: -15px;
        margin-right: -15px;
        cursor: pointer;
        border-bottom: 1px solid #313131;
        &::after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 18px;
          height: 18px;
          background-image: url(../images/plus.png);
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: contain;
          position: absolute;
          top: 20px;
          right: 15px;
          z-index: 9;
        }
        &.active {
          &::after {
            background-image: url(../images/minus.png);
          }
        }
      }
    }

    .footer-links {
      @media (min-width: 768px) {
        display: block !important;
      }
      @media (max-width: 991px) {
        margin-bottom: $spacer-2;
      }
      @media (max-width: 767px) {
        margin-bottom: 0;
        display: none;
      }

      li {
        @media (max-width: 767px) {
          text-align: center;
          padding: 0.25rem 0;
          background: $gray-200;
          margin: 0;
          border-bottom: .5px solid #adadad;
          border-top: .5px solid #fff;
          &:first-child {
            border-top: none;
          }
          &:last-child {
            border-bottom: none;
          }
          a {
            display: block;
            padding: 0.75rem 0;
          }
        }

        a {
          color: $white;
          font-size: 1.125rem;
          line-height: 1.25;
          font-weight: 400;

          &:hover {
            text-decoration: none;
            color: $yellow;
          }

          @media (max-width: 767px) {
            color: $black;
            &:hover {
              color: $red;
            }
          }
        }
      }
    }

    .social-links {
      text-align: center;
      margin-bottom: 2rem;

      li {
        &:not(:last-child) {
          margin-right: 2rem;
        }

        @media (max-width: 1199px) {
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }

        a {
          &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            width: 38px;
            height: 37px;
          }

          span.hidden {
            display: none;
          }
        }

        a.youtube {
          &:before {
            width: 45px;
            background-image: url(../images/midas-youtube-mob.png);
          }

          &:hover {
            &:before {
              background-image: url(../images/midas-youtube.png);
            }
          }
        }

        a.facebook {
          &:before {
            background-image: url(../images/midas-facebook-mob.png);
          }

          &:hover {
            &:before {
              background-image: url(../images/midas-facebook.png);
            }
          }
        }

        a.twitter {
          &:before {
            background-image: url(../images/midas-twitter-mob.png);
          }

          &:hover {
            &:before {
              background-image: url(../images/midas-twitter.png);
            }
          }
        }

        a.instagram {
          &:before {
            background-image: url(../images/midas-instagram-mob.png);
          }

          &:hover {
            &:before {
              background-image: url(../images/midas-instagram.png);
            }
          }
        }

        @media (max-width: 767px) {
          a.youtube {
            &:before {
              background-image: url(../images/midas-youtube-mob.png);
            }

            &:hover {
              &:before {
                background-image: url(../images/midas-youtube.png);
              }
            }
          }
          a.facebook {
            &:before {
              background-image: url(../images/midas-facebook-mob.png);
            }

            &:hover {
              &:before {
                background-image: url(../images/midas-facebook.png);
              }
            }
          }
          a.twitter {
            &:before {
              background-image: url(../images/midas-twitter-mob.png);
            }

            &:hover {
              &:before {
                background-image: url(../images/midas-twitter.png);
              }
            }
          }

        }
      }
    }

    .footer-logo {
      text-align: center;

      img {
        max-width: 100%;
        @media (max-width: 991px) {
          max-width: 130px;
          width: 100%;
        }
      }
    }
  }

  // end of .footer-inner

  .footer-bottom {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    background: $red;
    color: $white;
    font-size: 0.875rem;
    position: relative;
    overflow: hidden;

    a {
      color: $white;
    }

    .back-to-top {
      text-align: right;
      @media (max-width: 767px) {
        padding-top: 14px;
        text-align: center;
      }

      a {
        &::after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: contain;
          width: 18px;
          height: 18px;
          margin-left: 5px;
          background-image: url(../images/back-to-top.svg);
        }
      }
    }

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  // end of .footer-bottom

}

// end of .footer