body.midas-business{
    #cta-newsletter{
        background-color: #e5e5e5;
        h1{
            font-weight: 500;
        }
    }
}

.midas-business-portals{
    .mbp-item{
        @include clearfix;
        background-color: $gray-100;
        margin-bottom: 25px !important;
        @media (max-width: 767px) {
            max-width: 320px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        > div{
            height: 100%;
            @include clearfix;
            @media (max-width: 1199px) {
                width: 50% !important;
            }
            @media (max-width: 767px) {
                width: 100% !important;
            }
        }
        .mbp-content{
            float: left;
            width: 46.61%;
            padding: 1.25rem;
            
            .mbp-content-inner{
                h4{
                    padding-left: 60px;
                    position: relative;
                    height: 51px;
                    display: flex;
	                align-items: center;
                    .mb-menu-icon{
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 51px;
                        width: 51px;
                        background-color: $red;
                        @include border-radius( 50% );
                        padding: 10px;
                    }
                    
                }
                p{
                    margin-bottom: 10px; 
                    line-height: normal;
                }
                ul{
                    padding-left: 1rem !important;
                }
                @media (max-width: 1199px) {
                    h4{
                        font-size: 1.35rem;
                        br{
                            display: none;
                        }
                    }
                }
            }
            > p {
                padding-top: 10px;
                a.btn-primary{
                    padding: 1.125rem 1.125rem 1rem; 
                    line-height: 1.25;
                    text-align: center;
                    min-width: 175px;
                    letter-spacing: 1px;
                } 
            }

            @media (max-width: 767px) {
                padding: 2rem 1.25rem;
                .mbp-content-inner{
                    min-height: 1px !important;
                }
                > p{
                    padding-top: 16px;
                    text-align: center;
                }
            }
        }
        .mbp-photo{
            float: right;
            width: 53.39%;
            @include cover;
            img{
                display: none;
                
            }
            @media (max-width: 991px) {
                background: none !important;
                img{
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}