.search-locations{
    background-color: $gray-100;
    padding: 22px 3.31%;
    font-size: 0;
    > div{
        display: inline-block;
        &.or{
            font-size: 0.875rem;
            font-weight: 700;
            color: #999;
            text-align: center;
            text-transform: uppercase;
            width: 6.42%;
        }
    }
    .text-field{
       width: 28.83%;
    }
    .select-field{
        width: 19.82%;
    }
    .search-field{
        padding-left: 65px;
        width: 18.92%;
        button{
            width: 100%;
            padding: 0 10px;
            i{
                font-size: 28px;
                font-weight: 400;
                margin-right: 14px;
                position: relative;
                top: 3px;
                left: initial;
            }
        }
    }
    .location-field{
        padding-left: 50px;
        width: 25.23%;
        button{
            width: 100%;
            padding: 0 10px;
            i{
                font-size: 34px;
                margin-right: 15px;
                vertical-align: middle;
            }
        }
    }
    @media (max-width: 1199px) {
        padding: 20px;
        .search-field, .location-field{
            padding-left: 20px;
        }
    }
    @media (max-width: 991px) {
        .text-field, .select-field {
            width: 46.5%;
        }
        .search-field{
            width: 50%;
            padding: 18px 23px 0 0;
        }
        .location-field{
            width: auto;
            padding: 18px 4px 0 20px;
            button{
                padding: 0 20px;
            }
        }
    }
    @media (max-width: 767px) {
        padding: 0;
        background: none;
        > div{
            width: 100% !important;
            &.or{
                padding: 12px 0;
                text-align: left;
            }
        }   
        .location-field,  
        .search-field{
            padding: 18px 0 0;
            button{
                width: auto;
                padding: 0 30px 0 15px;
                .fa-map-marker{
                    margin-left: 5px;
                }
            }
        }
        
    }
}

// Location - Search Results
.show-all-stores{
    color: $red;
    border-bottom: 1px solid #d0d0d0;
}
.result-listing{
    height: auto;
    margin-top: 20px;
    @media (min-width:768px) {
        max-height: 90vh;
        overflow-y: auto;
    }
    @include clearfix;
    .result-list{
        padding: 20px 0 0 ;
        h4{
            padding: 20px 15px;
            background: $yellow;
            margin-bottom: 0;
        }

        &:first-child{
            padding: 0;
        }

        &.active {
            h4{
                padding-right: 85px;
                background: $yellow url(../images/midas-logo-pin-marker.png) no-repeat right 14px center;
                @include background-size( 60px auto );
            }
        }
        
        @media (max-width: 767px) {
            &:last-child{
                padding-bottom: 1.5rem;
            }
        }
    }
    .result-list-box{
        background-color: #f6f6f6;
        padding: 1rem .938rem;
        p{
            margin-bottom: 1rem;
            
        }
        .icon-direction{ 
            &:before {
                background-image: url(../images/midas-store-directions-icon.svg); 
            }
        }
        .spacer{
            margin-left: 8px;
            margin-right: 7px;
        }
        table{
            width: 100%;
            max-width: 250px;
            tr{
                th, td{
                    width: 50%;
                    &:last-child{
                        text-align: right;
                    }
                }
                th{
                    font-size: 15px;
                    font-weight: 700;
                }
                td{
                    font-size: 13px;
                    font-weight: 400;
                }
                .plus-sign{
                    color: $red;
                }
                .open, .closed{
                    background-color: #00b500;
                    color: $white;
                    font-size: 14px;
                    font-weight: 700;
                    display: inline-block;
                    padding: 0 4px;
                    text-transform: uppercase;
                    @include border-radius( 4px );
                }
                .open{
                    background-color: #00b500;
                }
                .closed{
                    background-color: $red;
                }
            }
        }
    }
}
.franchise-locations{
    .gm-style-iw-d div p:not(:last-child) {
        display: none !important;
    }
    
    .result-list-box p:not(:last-child) {
        display: none !important;
    }
}
