.page-header {
    padding: 1.625rem 0;
    &.has-banner{
        padding-bottom: 1.625rem;
    }
    .breadcrumbs {
        color: $black;
        font-size: 0.75rem;
        font-weight: 500;
        margin-bottom: .25rem;
        @media (max-width: 767px) {
            margin-bottom: 1rem !important;
        }
        a{
            color: #000;
        }
    }
	.test{
	color:#fff;
}
    .page-banner{
        padding-top: 1.875rem;
        background-repeat: no-repeat;
        position: relative;
        @media (min-width: 768px) {
            background: none !important;
        }
        @media (max-width: 767px) {
            height: 200px;
            @include cover;
        }
        @media (max-width: 425px) {
            height: 170px;
        }
        img{
            max-width: 100%;
            height: auto;
            margin: 0;
        }
        .banner-content{
            position: absolute;
            top: 65px;
            left: 30px;
            h1{
                color: $yellow;
                font-size: 4.063rem;
                font-weight: 700;
                line-height: 0.9;
                text-transform: uppercase;
                margin-bottom: 0.875rem;
                strong{
                    font-size: 4.625rem;
                    display: block;
                }
            }
            a.btn-primary{
                min-width: 275px;
                text-align: center;
            }
            @media (max-width: 1199px) {
                top: 55px;
                h1{
                    font-size: 3.125rem;
                    strong{
                        font-size: 3.563rem;
                    }
                }
                a.btn-primary{
                    min-width: 230px;
                }
            }
            @media (max-width: 767px) {
                top: 20px;
                left: 15px;
                h1{
                    font-size: 2.125rem;
                    strong{
                        font-size: 2.425rem;
                    }
                }
                a.btn-primary{
                    padding: 0 10px;
                    min-width: 184px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 44px;
                }
            }
            @media (max-width: 575px) {
                left: 30px;
                margin-right: -15px;
                margin-left: -15px;
            }
        }
    }

    .page-title{
        padding-top: 2rem;
    }
    .container-fluid{
        padding: 0;
        .page-banner img{
            width: 100%;
        }
	}

}

.main-content{
    padding-bottom: 4rem;
}

.extra-content{
    padding: 3.75rem 0;
}

.eb-content-banners{
    @media (max-width:991px) {
        text-align: center;
    }
    img{
        height: auto;
    }
    .mobile-img{
        max-width: 320px;
    }
}
