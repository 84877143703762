
@font-face {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style : normal;
           src : url("../fonts/fontawesome-webfont.eot");
           src : url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"),
                 url("../fonts/fontawesome-webfont.woff2") format("woff2"),
                 url("../fonts/fontawesome-webfont.woff") format("woff"),
                 url("../fonts/fontawesome-webfont.ttf") format("truetype"),
                 url("../fonts/fontawesome-webfont.svg#fontawesomeregular") format("svg");
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-Regular.eot');
    src: url('../fonts/AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-Regular.woff2') format('woff2'),
        url('../fonts/AvenirNext-Regular.woff') format('woff'),
        url('../fonts/AvenirNext-Regular.ttf') format('truetype'),
        url('../fonts/AvenirNext-Regular.svg#AvenirNext-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-Medium.eot');
    src: url('../fonts/AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-Medium.woff2') format('woff2'),
        url('../fonts/AvenirNext-Medium.woff') format('woff'),
        url('../fonts/AvenirNext-Medium.ttf') format('truetype'),
        url('../fonts/AvenirNext-Medium.svg#AvenirNext-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-DemiBold.eot');
    src: url('../fonts/AvenirNext-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-DemiBold.woff2') format('woff2'),
        url('../fonts/AvenirNext-DemiBold.woff') format('woff'),
        url('../fonts/AvenirNext-DemiBold.ttf') format('truetype'),
        url('../fonts/AvenirNext-DemiBold.svg#AvenirNext-DemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/AvenirNext-Bold.eot');
    src: url('../fonts/AvenirNext-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirNext-Bold.woff2') format('woff2'),
        url('../fonts/AvenirNext-Bold.woff') format('woff'),
        url('../fonts/AvenirNext-Bold.ttf') format('truetype'),
        url('../fonts/AvenirNext-Bold.svg#AvenirNext-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}






