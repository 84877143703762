body.sticky .main-header {
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    @include box-shadow(0 5px 9px 0 rgba(198, 198, 198, 0.42));

    .navbar-nav .nav-item {
      @media (min-width: 992px) {
        .nav-link {
          padding: 1.625rem 1.5rem;
        }
        a.btn-primary {
          margin-top: 0.875rem;
        }
      }
    }
  }

  .top-bar {
    display: none;
  }

  .navbar-brand img {
    height: 55px;
    width: auto;
  }
}

.top-bar {
  background: $black;
  color: $white;
  padding-top: 6px;
  padding-bottom: 7px;
  @media (max-width: 991px) {
    display: none;
  }

  .quick-links {
    ul {
      li {
        @extend .list-inline-item;

        &.list-inline-item:not(:last-child) {
          margin-right: 1rem;
        }

        &.store-locator-link {
          font-weight: 600;

          a {
            &:before {
              width: 18px;
              height: 24px;
              margin-right: 10px;
              background-image: url(../images/header-store-locator-icon.svg);
            }

            &:hover {
              &:before {
                background-image: url(../images/header-store-locator-icon-mob.svg);
              }
            }
          }
        }

        &.need-help-link {
          a {
            font-weight: 700;
          }
        }

        &.call-us-link {
          font-style: italic;
          font-weight: 600;

          a {
            &:before {
              width: 20px;
              height: 20px;
              margin-right: 10px;
              background-image: url(../images/header-phone-icon.svg);
            }

            &:hover {
              &::before {
                background-image: url(../images/header-phone-icon-mob.svg);
              }
            }
          }
        }

        a {
          text-decoration: none;
          color: $white;
          font-size: 0.875rem;
          line-height: 1.5rem;

          &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
          }

          &:hover {
            color: $red;
          }
        }
      }
    }

    &.right {
      @media (min-width: 576px) {
        text-align: right;
      }
    }
  }

  // end of .quick-links
}

// end of .top-bar

body.page-id-13, body.parent-pageid-13 {
  .nav-item.why-pick-midas-menu {
    @media (min-width: 992px) {
      > a {
        background: $yellow;
        color: $black;
      }
    }
  }
}

body.page-id-10, body.parent-pageid-10 {
  .nav-item.automotive-services-menu {
    @media (min-width: 992px) {
      > a {
        background: $yellow;
        color: $black;
      }
    }
  }
}

body.page-id-11 {
  .nav-item.midas-business-menu {
    @media (min-width: 992px) {
      > a {
        background: $yellow;
        color: $black;
      }
    }
  }
}

.navbar {
  padding: 0;
  background-color: $white;
  @media (max-width: 991.98px) {
    &.navbar-expand-lg > .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (max-width: 991px) {
    position: static;
    top: -100px;
    transition: .5s;
  }

  .quick-links {
    ul {
      li {
        @extend .list-inline-item;
      }
    }
  }

  .navbar-toggler {
    background-color: transparent !important;
    padding: 0;
    min-width: 84px;
    text-align: left;
    @media (max-width: 991.98px) {
      padding-left: 15px;
    }

    .navbar-toggler-icon {
      background-image: url(../images/hamburger-menu-close.svg);
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: contain;
      width: 44px;
      height: 33px;
      transition-duration: .1s;
    }

    &.collapsed {
      .navbar-toggler-icon {
        background-image: url(../images/hamburger-menu.svg);
      }
    }
  }

  .navbar-brand {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 991.98px) {
    .quick-links {
      padding-right: 15px;
    }
  }

  .store-locator-link,
  .call-us-link {
    a {
      &::before {
        content: '';
        width: 34px;
        height: 34px;
        background-image: url(../images/header-store-locator-icon.png);
        display: inline-block;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
      }
    }

    span {
      display: none !important;
    }
  }

  .call-us-link {
    margin-left: 0.75rem;

    a {
      &::before {
        background-image: url(../images/header-phone-icon.png);
      }
    }
  }

  .navbar-nav {
    display: block;
    @media (max-width: 991px) {
      overflow-y: auto;
      max-height: calc(100vh - 80px);
      height: 100%;
    }

    .nav-item {
      @media (min-width: 992px) {
        float: left;
        margin-left: 2px;
        &:first-child {
          float: right;
          margin-left: 1.5rem;
        }
      }
      @media (min-width: 1200px) {
        &:first-child {
          margin-left: 1.875rem;
        }
      }
      @media (max-width: 991px) {
        &:after {
          content: '';
          background-color: $gray-700;
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: -1px;
          left: 0;
        }
      }

      .nav-link {
        color: $black;
        font-size: 1.125rem;
        line-height: 1.25;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;

        &:hover {
          background: $yellow;
          color: $black;
        }

        &.action {
          background: $red;
          color: $white;
          text-transform: uppercase;

          &:hover {
            background: $yellow;
            color: $black;
          }
        }

        @media (max-width: 991px) {
          text-align: left;
          padding: 14px 15px;
          line-height: 27px;
          text-transform: capitalize;
          color: $white;
          background: $black;

          &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 37px;
            height: 27px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            margin-right: 15px;
          }
          &:hover {
            background: $black;
            color: $white;
          }

          &.action {
            &:hover {
              background: $red;
              color: $white;
            }
          }
        }
      }

      // end of .nav-link

      &.has-children {
        @media (max-width: 991px) {
          position: relative;
          margin-bottom: 1px;
        }

        .nav-link {
          position: relative !important;

          &:hover:after {
            opacity: 1;
            visibility: visible;
            bottom: 12px;
          }

          &:after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 14px;
            background-image: url(../images/top-menu-arrow-down.svg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            transform: translateX(-50%);
            position: absolute;
            left: 50%;
            bottom: 20px;
            opacity: 0;
            visibility: hidden;
            @media (max-width: 991px) {
              display: none;
            }
          }
        }

        span.submenu-trigger {
          display: none;
          @media (max-width: 991px) {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 56px;
            background-color: rgba(221, 221, 221, 0.15);
            z-index: 9;
            cursor: cell;
            &:after {
              content: "";
              display: inline-block;
              vertical-align: middle;
              width: 14px;
              height: 14px;
              background-image: url(../images/plus.png);
              background-repeat: no-repeat;
              background-position: 50% 50%;
              background-size: contain;
              position: absolute;
              top: 20px;
              right: 15px;
              z-index: 9;
            }
            &.active {
              &:after {
                background-image: url(../images/minus.png);
              }
            }
          }
        }

        .submenu {
          position: relative;
          width: 100%;
          background-color: #d8d8d8;
          box-shadow: 0 3.5px 5px 0 rgba(0, 0, 0, 0.24);
          @media (min-width: 992px) {
            position: absolute;
            top: 130%;
            left: 0;
            z-index: 99999;
            @include transition(all, 0.15s);
            visibility: hidden;
            opacity: 0;
            display: block !important;
            pointer-events: none;
          }
          @media (max-width: 991px) {
            &:after {
              content: '';
              background-color: $gray-700;
              position: absolute;
              width: 100%;
              height: 1px;
              top: -1px;
              left: 0;
            }
          }

          &:before {
            content: '';
            display: block;
            width: 50%;
            height: 100%;
            background-color: #ececec;
            position: absolute;
            top: 0;
            left: 0;
          }

          .submenu-close:after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 17px;
            height: 17px;
            background-image: url(../images/top-menu-close.svg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            position: absolute;
            top: 17px;
            right: 17px;
            z-index: 9;
            cursor: pointer;
            @media (max-width: 991px) {
              display: none !important;
            }
          }

          .container {
            z-index: 9;
            position: relative;

            > .row {
              @media (min-width: 992px) {
                > div {
                  max-width: 30%;
                  @include flex(0 0 30%);

                  &:first-child {
                    max-width: 70%;
                    @include flex(0 0 70%);
                  }

                  &:last-child {
                    padding-left: 1.375em;
                    border-left: 1.75rem solid #ececec;
                  }
                }
              }

              > div:first-child {
                background: #ececec;
              }

              > div:last-child {
                background: #d8d8d8;
              }
            }
          }

          .block-left {
            ul {
              margin-left: -15px;
              margin-right: -15px;
              padding: 0;
              overflow: hidden;
              border-top: 1px solid $white;
              @media (min-width: 992px) {
                padding: 13px 0;
                border-top: 0;
              }

              li {
                padding: 7px 15px;

                @media (min-width: 576px) {
                  float: left;
                  width: 50%;
                }
                @media (max-width: 991px) {
                  border-bottom: 1px solid $white;
                  padding-left: 68px;
                }

                a {
                  font-size: 1.25rem;
                  color: $gray-secondary;
                  border-radius: 40px;
                  line-height: 68px;
                  padding: 4px 5px;
                  display: block;

                  span {
                    margin-left: 0.15rem;
                  }

                  &:hover {
                    path {
                      fill: $yellow;
                    }

                    .t_cls-1, .t_cls-2, .cls-2, .cls-4 {
                      stroke: $yellow !important;
                    }
                  }

                  .menu-item ._svg {
                    background: #323232;
                    width: 73px;
                    height: 73px;
                    border-radius: 50%;
                    margin-right: 2rem;

                    path {
                      fill: white;
                      background-color: #333;
                    }

                    .cls-2, .cls-4 {
                      stroke: #fff !important;
                      fill: #323232 !important;
                    }

                    @media (max-width: 991px) {
                      display: none;
                    }
                  }

                  @media (max-width: 1199px) {
                    line-height: 63px;
                  }
                  @media (max-width: 991px) {
                    font-size: 1.25rem;
                    line-height: 27px;
                  }
                  @media (max-width: 767px) {
                    font-size: 1.125rem;
                    padding: 0;
                  }

                  &:before {
                    //content: '';
                    display: inline-block;
                    vertical-align: middle;
                    width: 73px;
                    height: 73px;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: 60%;
                    background-color: $gray-800;
                    @include border-radius(50%);
                    margin-right: 40px;
                    @media (max-width: 1199px) {
                      width: 63px;
                      height: 63px;
                      margin-right: 20px;
                    }
                    @media (max-width: 991px) {
                      width: 40px;
                      height: 40px;
                      margin-right: 15px;
                    }
                  }

                  &:hover {
                    text-decoration: none;
                    background: $yellow;
                    @media (max-width: 991px) {
                      background: none;
                    }
                  }
                }

                &.current-menu-item {
                  @media (max-width: 991px) {
                    background: $yellow;
                  }

                  a {
                    text-decoration: none;
                    background: $yellow;
                    @media (max-width: 991px) {
                      background: none;
                    }
                  }
                }
              }
            }
          }

          .block-right {
            padding: 26px 0;

            .nav-subheading {
              font-size: 1rem;
              font-weight: 700;
              color: $red;
              border-bottom: 1px solid $gray-300;
              padding-bottom: 5px;
              margin-bottom: 1.25rem;
            }

            ul {
              margin-bottom: 1.75rem;

              li {
                a {
                  color: $gray-800;
                  font-weight: 500;

                  &:hover {
                    color: $red;
                    text-decoration: none;
                  }
                }
              }
            }

            .promo-banner {
              img {
                max-width: 100%;
              }
            }
          }
        }

        // end of .submenu

        //&.automotive-services-menu,
        &:hover {
          @media (min-width: 992px) {
            > a {
              background: $yellow;
              color: $black;
            }
            .submenu {
              top: 100%;
              visibility: visible;
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
      }

      // end of &.has-children


      &.why-pick-midas-menu {
        .block-left {
          ul {
            li {
              &:nth-of-type(1) a:before {
                background-image: url(../images/icons/why-midas.png);
              }

              &:nth-of-type(1) a:hover:before {
                background-image: url(../images/icons/why-midas-yellow.png);
              }

              a:before {
                background-image: url(../images/icons/why-midas.png);
              }

              a:hover:before {
                background-image: url(../images/icons/why-midas-yellow.png);
              }
            }
          }
        }
      }

      &.automotive-services-menu {
        .block-left {
          ul {
            li {
              &:nth-of-type(1) a:before {
                background-image: url(../images/icons/logbook-service.png);
              }

              &:nth-of-type(1) a:hover:before, &.current-menu-item:nth-of-type(1) a:before {
                background-image: url(../images/icons/logbook-service-yellow.png);
              }

              &:nth-of-type(2) a:before {
                background-image: url(../images/icons/brakes-service.png);
              }

              &:nth-of-type(2) a:hover:before, &.current-menu-item:nth-of-type(2) a:before {
                background-image: url(../images/icons/brakes-service-yellow.png);
              }

              &:nth-of-type(3) a:before {
                background-image: url(../images/icons/4-wd-service.png);
              }

              &:nth-of-type(3) a:hover:before, &.current-menu-item:nth-of-type(3) a:before {
                background-image: url(../images/icons/4-wd-service-yellow.png);
              }

              &:nth-of-type(4) a:before {
                background-image: url(../images/icons/tyre-service.png);
              }

              &:nth-of-type(4) a:hover:before, &.current-menu-item:nth-of-type(4) a:before {
                background-image: url(../images/icons/tyre-service-yellow.png);
              }

              &:nth-of-type(5) a:before {
                background-image: url(../images/icons/extensive-service.png);
                background-size: 73%;
              }

              &:nth-of-type(5) a:hover:before, &.current-menu-item:nth-of-type(5) a:before {
                background-image: url(../images/icons/extensive-service-yellow.png);
              }

              &:nth-of-type(6) a:before {
                background-image: url(../images/icons/suspension-service.png);
              }

              &:nth-of-type(6) a:hover:before, &.current-menu-item:nth-of-type(6) a:before {
                background-image: url(../images/icons/suspension-service-yellow.png);
              }

              &:nth-of-type(7) a:before {
                background-image: url(../images/icons/essential-service.png);
                background-size: 73%;
              }

              &:nth-of-type(7) a:hover:before, &.current-menu-item:nth-of-type(7) a:before {
                background-image: url(../images/icons/essential-service-yellow.png);
              }

              &:nth-of-type(8) a:before {
                background-image: url(../images/icons/exhaust-service.png);
                background-size: 73%;
              }

              &:nth-of-type(8) a:hover:before, &.current-menu-item:nth-of-type(8) a:before {
                background-image: url(../images/icons/exhaust-service-yellow.png);
              }
            }
          }
        }
      }

      a.btn-primary {
        margin-top: 1.375rem;
        padding: 0.875rem 1.5rem 0.688rem;
        font-size: 1.125rem;
        line-height: 1.25;
      }

      @media (max-width: 991px) {
        a.btn-primary {
          margin-top: 0;
          display: block;
          position: relative;
          padding: 14px 15px;
          background-color: $red !important;
          color: $white !important;
          text-align: left;

          &:before {
            background-image: url(../images/icons/book-service-yellow.png);
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 37px;
            height: 27px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            margin-right: 15px;
          }
        }
        &:nth-of-type(2) a:before {
          background-image: url(../images/icons/why-midas-yellow.png);
        }
        &:nth-of-type(3) a:before {
          background-image: url(../images/icons/services-yellow.png);
        }
        &:nth-of-type(4) a:before {
          background-image: url(../images/midas-logo.png);
        }
      }
    }
  }

  @media (min-width: 992px) {
    &.navbar-expand-lg .navbar-toggler,
    &.navbar-expand-lg .quick-links {
      display: none;
    }
    &.navbar-expand-lg .navbar-nav .nav-link {
      padding: 2.188rem .5rem;
      font-size: 1.063rem;
    }
  }
  @media (min-width: 1200px) {
    &.navbar-expand-lg .navbar-nav .nav-link {
      padding: 2.188rem 1.5rem;
      font-size: 1.125rem;
    }
  }

  @media (max-width: 991px) {
    .navbar-brand {
      padding: 10px 0;

      img {
        max-width: 95px;
        height: auto;
      }
    }
    #main-menu {
      position: fixed;
      top: 100px;
      left: 0;
      width: 100%;
      z-index: 9999;
      opacity: 0;
      transition-duration: .15s;

      &.show {
        display: block !important;
        top: 75px;
        opacity: 1;
      }

      .navbar-nav .nav-item.has-children .submenu .container {
        max-width: 100%;
      }
    }
  }

}
@media (max-width: 1112) {
        .navbar-expand-lg .navbar-collapse {
        flex-basis: unset;
    }
}
// end of .navbar


