// Location - Store Details
.right-panel-portion{
    padding-right: 0;
    .single-store-reviews{
        text-align: center;
        @media (min-width: 992px) {
            padding-right: 8px;
        }
        .rplg-badge-cnt{
            text-align: center;
            .rplg-badge-logo>svg{
                width: 21px !important;
                height: 21px !important;
            }
        }
    }
    .single-store-gmap {
        padding-right: 0;
        @media (min-width: 992px) {
          padding-left: 23px;
        }
      
        .book-now-bttn {
          font-size: 1rem;
          width: 100%;
        }
      }
}

// Badge//

.single-store-page-title {
  h3 {
    padding: 20px 15px;
    background: #ffe600;
    margin-bottom: 0;
  }

  h1 {
    font-size: 1.4rem;
    @extend h3;

  }
}

.store-details {
  .store-infos {
    background-color: #f6f6f6;
    padding: 1rem .938rem;
    @media(min-width: 992px) {
      min-height: 304px;
    }

    p {
      margin-bottom: 1.125rem;
    }

    .icon-direction {
      &:before {
        background-image: url(../images/midas-store-directions-icon.svg);
      }
    }

    .fa-phone {
      font-size: 22px;
      margin-left: 10px;
    }


    table {
      &.store-trading-hours {
        .th-additional-details {
          display: none;
        }

        .th-additional-details-toggle {
          cursor: pointer;
        }

      }

      width: 100%;
      max-width: 250px;

      tr {
        th, td {
          width: 50%;

          &:last-child {
            text-align: right;
          }
        }

        th {
          font-size: 15px;
          font-weight: 700;
        }

        td {
          font-size: 13px;
          font-weight: 400;
        }

        .plus-sign {
          color: $red;
        }

        .open, .closed {
          background-color: #00b500;
          color: $white;
          font-size: 14px;
          font-weight: 700;
          display: inline-block;
          padding: 0 4px;
          text-transform: uppercase;
          @include border-radius(4px);
        }

        .open {
          background-color: #00b500;
        }

        .closed {
          background-color: $red;
        }
      }
    }

    .social_links a {
      display: inline-block;
      background-color: $red;
      width: 18px;
      height: 18px;
      line-height: 20px;
      text-align: center;
      border-radius: 5px;

      .fa {
        color: #fff;
        font-size: 16px;
      }
    }

    .follow-us-links {
      @include list-none;

      li {
        float: left;

        &:first-child {
          margin-top: 3px;
          margin-right: 4px;
        }

        a {
          display: inline-block;
          background-color: $red;
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          margin-left: 8px;
          @include border-radius(5px);

          .fa {
            color: #fff;
            font-size: 26px;
            line-height: 32px;
          }

          &:hover {
            background-color: $yellow;

            .fa {
              color: #000;
            }
          }
        }
      }
    }
  }

  .cta-enquire {
    margin-top: 35px;
    margin-bottom: 1.5rem !important;
    text-align: center;

    .btn-primary {
      @media(min-width: 992px) {
        width: 100%;
        margin-bottom: 0;

      }
    }
  }

  .featured-block {
    position: relative;
    @media(min-width: 992px) {
      max-height: 205px;
      overflow: hidden;
    }

    .btn-play {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .31);
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }

  .store-slider {
    position: relative;

    .btn-play {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .31);
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-direction: column;
      flex-direction: column;

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  .store-hours {
    table {
      background-color: #5d5d5d;
      padding: 30px 20px;
      width: 100%;
      display: block;
      min-height: 300px;
      max-width: 300px;
      margin: 0 auto;

      tr {
        th {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.25;
        }

        td {
          width: 70%;
          text-align: right;

          &:first-child {
            width: 30%;
            text-align: left;
          }
        }

        .open, .closed {
          background-color: #00b500;
          color: $white;
          font-size: 14px;
          font-weight: 700;
          display: inline-block;
          padding: 0 4px;
          text-transform: uppercase;
          @include border-radius(4px);
        }

        .open {
          background-color: #00b500;
        }

        .closed {
          background-color: $red;
        }
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        padding: 24px;
        tr td {
          font-size: 14px;
        }
      }
    }
  }

  .store-gmap {
    max-height: 304px;
  }
}

.auto-services-available {
  .asa-item {
    @media(max-width: 767px) {
      display: none;
    }
  }

  text-align: center;

  h3 {
    margin: 1.5rem 0 2rem;
  }

  .services-item {
    margin-bottom: 1.5rem;

    a {
      color: #565656;
      display: flex;
      align-items: center;
      cursor: pointer;
      @media (max-width: 575px) {
        position: relative;
        min-height: 42px;
      }
    }

    .title-container {
      display: table-cell;
      vertical-align: middle;
      padding-left: 1.5rem;
      text-align: left;
    }

    .service-logo-container {
      display: table-cell;
      line-height: 50px;
      height: 55px;
      width: 55px;
      vertical-align: middle;
      text-align: center;
      margin-right: .5rem;
      border-radius: 50%;
      display: -ms-flexbox;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-direction: column;
      flex-direction: column;

      img {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
  }

  #loadmore-container {
    margin-bottom: 1.5rem !important;
  }

  ul {
    @include list-none;

    @media (max-width: 575px) {
      display: inline-block;
      margin: 0 auto;
      width: 100%;
    }

    li {
      display: inline-block;
      text-align: left;
      font-size: 15px;
      margin-bottom: 1.5rem !important;
      vertical-align: top;
      padding-left: 1.3em;
      @media (max-width: 767px) {
        margin-bottom: 2rem !important;
        width: 200px;
        padding: 0;
      }
      @media (max-width: 575px) {
        margin-bottom: 1rem !important;
        width: 195px;
      }

      a {
        color: #565656;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media (max-width: 575px) {
          position: relative;
          min-height: 42px;
        }
      }
    }
  }
}

.store-manager {
  h5 {
    padding: 1.25rem;
  }

  .widget-box {
    max-width: 100%;
    background: whitesmoke;

    .widget-box-inner {
      padding: 1.675rem 1.25rem;
      height: 263px;
      overflow: hidden;
      overflow-y: scroll;
      @include clearfix;

      > div {
        float: left;

        &.sm-infos {
          width: 65%;

          p {
            font-size: 1rem;
          }
        }

        &.sm-photo {
          width: 35%;

          img {
            height: auto;
          }
        }
      }
    }

  }

  .store-slider {
    img {
      max-height: 327px;
      height: auto;
    }

  }
}

.store-testimonials {
  padding-top: 45px;

  .store-testi-headings {
    text-align: center;
    border-top: 2px solid #cfcfcf;
    padding-top: 45px;

  }

  .store-carousel {
    position: relative;
    padding-top: 25px;

    .owl-carousel {
      height: 100%;
      padding: 0 45px;
      @media (max-width: 575px) {
        padding: 0 1rem;
      }

      .sc-item {
        height: 100%;
        padding: 37px;
        text-align: left;
        @extend %bgGradient;

        p {
          font-size: 1.125rem;
        }

        .matchItem {
          padding-bottom: 1.625rem;
          @media (max-width: 992px) {
            height: auto !important;
          }
        }

        .st-author {
          max-width: 230px;

          p {

            font-weight: 600;
            font-style: italic;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .store-testi-headings {
      border-top: 0;
      padding-top: 10px;
    }
    .store-carousel {
      padding-top: 10px;
    }
  }
}
