.follow-simple-process{
    text-align: center;
    padding-top: 0;
    ul{
        @include list-none;
        li{
            font-size: 0.938rem;
            letter-spacing: 0.8px;
        }
    }
}
.red-circle{
    height: 167px;
    width: 167px;
    background-color: $red;
    color: #fff;
    font-size: 1.563rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 40px auto 18px;
    overflow: hidden;
    @include border-radius( 50% );
    @include flexCenter();
}

.right-options{
    h3{
        text-align: center;
    }
    img{
        height: auto;
    }
    ul.nav{
        width: 100%;
        padding-top: 30px;
        > li{
            position: relative;
            width: 50%;
            padding: 50px 75px 125px;
            @include linearGradient(#d0d0d0, #f2f2f2, 0, 0);          

            .heading-wrap{
                display: flex;
                justify-content: space-between;
                margin-bottom: 24px;
                > div{
                    display: flex;
                    align-items: center;
                }
            }

            @media (min-width: 768px) {
                .heading-wrap{
                    pointer-events: none;
                }
                .content-wrap{
                    display: block !important;
                }
                .acc-toggle-mobile{
                    display: none !important;
                }
            }

            h4{
                font-weight: 500;
            }
            ul{
                li{
                    margin-left: 40px;
                    margin-bottom: 16px;
                }
            }
            a.trigger-link-tab{
                @media (min-width: 768px) {
                    position: absolute;
                    right: 25px;
                    bottom: 30px;
                }
                line-height: 2.38;
                padding-right: 32px;
                span{
                    text-decoration: underline;
                }
                &:after{
                    position: absolute;
                    top: 15px;
                    right: 0;
                    content: '';
                    width: 16px;
                    height: 10px;
                    background: url(../images/icons/triangle@2x.png) no-repeat;
                    background-size: contain;
                }
            }

            &.active{
                @media (min-width: 768px) {
                    background: #f2f2f2;
                }
            }
            &.open{
                h4{
                    font-weight: 700;
                }
                a.trigger-link-tab{
                    &:after{
                        @include transform( rotate(180deg) );
                    }
                }
            }

            .acc-toggle-mobile{
                display: none;
            }
        }
    }

    .tab-content{
        @include clearfix;
        .tab-pane {
            background-color: #f2f2f2;
            padding: 50px 85px 55px;
            h4{
                font-weight: 700;
            }
            ul.two-columns{
                padding-bottom: 40px;
                @include clearfix;
                @include list-none;
                > li{
                    float: left;
                    width: 50%;
                    h6{
                        font-weight: 700;
                        text-align: center;
                        padding-bottom: 60px;
                    }
                    ol{
                        li{
                            margin-left: 40px;
                            margin-bottom: 16px;
                        }
                    }
                    &:last-child{
                        padding-left: 30px;
                    }
                }
            }
            .sub-accordion {
                margin-top: 0;
                .card{
                    background: none;
                    .card-header h5 .btn-link{
                        font-size: 17px;
                        font-weight: 600;
                        text-transform: none;
                    }
                    &:last-child{
                        border-bottom: 1px solid #b7b7b7;
                    }
                }
            }
        }
        .fade:not(.show) {
            opacity: 1 !important;
        }
    }
}


@media (max-width: 1199px) {
    .right-options{
        padding-top: 2rem;
        ul.nav{
            > li{
                padding: 35px 30px 100px;
            }
        }
        .tab-content .tab-pane{
            padding: 50px 50px 55px;
        }
    }
}

@media (max-width: 991px) {
    .red-circle {
        height: 130px;
        width: 130px;
        margin-top: 25px;
        img{
            width: 80px;
            height: 80px;
        }
    }

    .right-options{
        padding-top: 2rem;
        ul.nav{
            > li{
                padding: 30px 20px 85px;
                .heading-wrap{
                    > div:last-child{
                        display: none;
                    }
                }
                ul li{
                    margin-left: 20px;
                    line-height: 1.2;
                }
                a.trigger-link-tab{
                    padding-right: 25px;
                    &:after{
                        width: 12px;
                    }
                    span > span{
                        display: none;
                    }
                }
            }
        }
        .tab-content .tab-pane{
            padding: 45px 40px;
            ul.two-columns > li{
                h6{
                    padding-bottom: 20px;
                }
                ol li{
                    margin-left: 0;
                    margin-bottom: 12px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    
    .red-circle {
        height: 105px;
        width: 105px;
        margin: 1rem auto;
        img{
            width: 60px;
            height: 60px;
        }
    }
    .follow-simple-process {
        .red-circle {
            position: absolute;
            right: 0;
            bottom: auto;
            margin: 0;
        }
    }
    .right-options{
        padding-top: 1rem;
        ul.nav{
            padding-top: 20px;
            > li{
                background: #dfdede;
                padding: 0;
                width: 100%;
                .heading-wrap{
                    margin-bottom: 0;
                    padding: 20px;
                    cursor: pointer;
                    position: relative;
                    > div:last-child{
                        display: none;
                    }
                    h4{
                        font-weight: 600;
                    }
                    @include toggleIcons;
                }
                a.trigger-link-tab,
                .content-wrap{
                    display: none;
                    padding: 0 20px 30px;
                }
                
                &:last-child{
                    background: #f2f2f2;
                }

                .acc-toggle{
                    line-height: 2.38;
                    padding-right: 25px;
                    position: relative;
                    color: $red;
                    margin: 24px 0 0;
                    text-align: right;
                    cursor: pointer;
                    span{
                        text-decoration: underline;
                    }
                    &:after{
                        position: absolute;
                        top: 15px;
                        right: 0;
                        content: '';
                        width: 16px;
                        height: 10px;
                        background: url(../images/icons/triangle@2x.png) no-repeat;
                        background-size: contain;
                        transition: $transition-base; 
                    }
                    &.active-toggle{
                        &:after{
                            @include transform( rotate(180deg) );
                        }
                    }
                }

                .acc-toggle-mobile{
                    padding-top: 30px;
                    .two-columns{
                        list-style: none;
                        padding: 0;
                        margin-bottom: 3rem;
                    }
                    .card{
                        background: none;
                    }
                }
    
            }
        }

        .row-02{
            .col-md-6{
                padding-top: 35px;
            }
        }
    }
}

@media (max-width: 480px) {
    .right-options{
        .row-01 > div {
            padding: 0;
        }
    }
}

