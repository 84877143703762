.styled-form {
    .form-heading {
        background-color: $yellow;
        padding: 2rem 2rem 1rem;
        border-bottom: 9px solid $red;
        box-shadow: 0 2px 5px #7d7d7d;
        @include border-radius(24px 24px 0 0);

        h2.form-title {
            font-weight: 700;
            color: #000;
        }
    }

    .form-body {
        padding: 15px 0 0;
        border-radius: 0 0 24px 24px;
        background: #ebebeb;
        box-shadow: 0px 2px 5px #7d7d7d;

        .form-section,
        .styled-form_wrapper {
            border-bottom: none !important;
            overflow-x: hidden;
            padding: 0 45px 0;
            border-bottom: 2px solid #d0d0d0;

            .section-heading {
                border: none;
                padding-bottom: 0;
                margin-bottom: 0 !important;
                width: calc(100% + 90px) !important;
                margin-left: -45px !important;
                padding-left: 2.5em;

                h2 {
                    color: $red;
                    font-weight: 700;
                    font-size: 1.625rem;

                    span {
                        font-size: 14.5px;
                        font-weight: 500;
                        font-style: italic;
                        color: #000;
                        display: block;
                        padding-top: 5px;
                    }
                }

                &:not(:first-child) {
                    border-top: 2px solid #d0d0d0;
                    padding-top: 2rem !important;
                }
            }

            &.last,
            &:last-child {
                border-bottom: 0;
            }

            .ginput_container_consent {
                max-width: 531px;
                margin: 0 auto !important;

                .gfield_consent_label {
                    display: inline;
                    font-weight: 500;
                }

                input[type=checkbox] {
                    height: 20px;
                    width: 20px;
                    display: inline;
                    vertical-align: text-bottom;
                    margin-right: 10px;
                    cursor: pointer;
                    @media (max-width: 767px) {
                        height: 15px;
                        width: 15px;
                        vertical-align: unset;
                        margin-right: 5px;
                        top: 2px;
                        position: relative;
                    }
                }
            }

            .gform_footer {
                text-align: center;
                padding-bottom: 4rem;

                input {
                    padding: 10px 0;
                    max-width: 275px;
                    font-size: 19px;
                }
            }

            @media (max-width: 424px) {
                padding: 0 1rem 0;
            }
        }

        .gform_wrapper .gform_body .gform_fields .gfield .ginput_container.ginput_container_consent {
            margin: 0 auto !important;
        }

        .styled-form_wrapper {
            .gsection {
                width: 100%;
            }

            .gfield {
                display: inline-block;
                width: 100%;

                select,
                input[type=text],
                input[type=select],
                input[type=textarea] {
                    width: 100% !important;
                }

                .button-field {
                    display: block;
                    position: relative;
                    padding-left: 4rem;

                    &:before {
                        content: "or";
                        font-size: .875rem;
                        font-weight: 700;
                        color: #999;
                        text-transform: uppercase;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    i {
                        font-size: 1.5rem;
                    }

                    button {
                        width: 100%;
                        @media (max-width: 1199px) {
                            font-size: 0.8rem;
                            padding: 0 20px;
                        }
                    }
                }
            }

            .gform-fields {
                @include list-none;
                @include clearfix;
            }

            .one-third {
                width: 33.33%;
                padding-left: 2rem;
                vertical-align: top;
                @media (max-width: 767px) {
                    padding-left: 0;
                }
            }
            .two-third {
                width: 66.67%;
                padding-left: 2rem;
                vertical-align: top;
                @media (max-width: 767px) {
                    padding-left: 0;
                }
            }

            .clean {
                margin: 0 !important;
            }
        }

        .gsection_description {
            font-style: italic;
            font-weight: 500;
            padding-bottom: .5rem;
        }

        span.gfield_required {
            display: inline !important;
        }
    }

    //end of .form-body
    .form-footer {
        text-align: center;
        padding: 4px 0 50px;

        input[type="submit"] {
            font-size: 19px;
            line-height: 74px;
            letter-spacing: 1.2px;
            min-width: 275px;
        }
    }

    .gform_confirmation_message {
        padding: 0 50px 100px 50px
    }
}

@media (max-width: 1199px) {
    .styled-form .form-body .form-fields {
        .field {
            padding-left: 25px;

            &.location-field {
                .button-field {
                    padding-left: 40px;

                    i {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .styled-form .form-body {
        .styled-form_wrapper {
            .one-third {
                width: 50%;
            }

            .two-third {
                width: 100%;
            }
        }
    }
}

@media (max-width: 767px) {
    .earn-points-box {
        padding-top: 30px;
    }
    .styled-form {
        margin-top: 50px;

        .form-body {
            .form-section {
                padding: 25px 30px 5px;
            }

            .styled-form_wrapper {
                .one-third {
                    width: 100%;
                }
            }
        }
    }
}

.cta-velocity-coupon {
    text-align: right;

    > a {
        display: inline-block;

        &:first-child {
            margin-right: 30px;
        }

        @media (max-width: 1199px) {
            img {
                max-width: 235px;
            }
        }
    }

    @media (max-width: 991px) {
        text-align: center;
        padding: 30px 0 20px;
    }
    @media (max-width: 767px) {
        > a {
            display: block;

            &:first-child {
                margin-right: 0;
                margin-bottom: 20px;
            }

            img {
                max-width: 320px;
            }
        }
    }
}

.cta-velocity-points {
    border-top: 5px solid #eceff6;
    @include cover;

    h1, li {
        color: #000;
    }

    .section-header-title {
        position: relative;
        padding-right: 215px;
        height: 150px;
        margin-bottom: 24px !important;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: -10px;
            width: 205px;
            height: 150px;
            background: #fff url(../images/velocity-logo@2x.png) no-repeat 50% 50%;
            @include background-size(150px auto);
            @include border-radius(0 0 20px 20px);
        }

        h1 {
            position: absolute;
            left: 0;
            right: 210px;
            @include Valign();
        }
    }

    p {
        font-size: 13.5px;
        color: #000;
    }

    .btn-primary {
        padding: 0 30px;
    }

    @media (max-width: 991px) {
        background-position: 0 50%;
        .section-header-title {
            &:before {
                right: 0;
            }
        }
    }
    @media (max-width: 480px) {
        .section-header-title {
            padding-top: 215px;
            padding-right: 0;
            height: auto;

            &:before {
                left: -15px;
                top: 35px;
                right: initial;
                background-position: 0 50%;
                @include border-radius(0 20px 20px 0);
            }

            h1 {
                position: static;
                transform: none;
            }
        }
    }
}

.missing-velocity-points {
    h3 {
        font-weight: 500;
    }

    .styled-form {
        margin-top: 50px;
    }
    #evp-section {
        height: 100px;
        margin-top: -50px;
    }
    #cmvp-section {
        height: 100px;
        margin-top: -100px;
    }

    form.styled-form {
        margin-top: 0;
    }

    @media (min-width: 767px) {
        .evp-bottom-content {
            display: block !important;
        }
    }
    @media (max-width: 767px) {
        .evp-bottom-wrap {
            padding: 0 1rem;

            h4 {
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                @include clearfix;
            }
        }
        .evp-bottom-header-title {
            position: relative;
            padding: 27px 0 !important;
            text-align: left !important;
            margin: 0;
            cursor: pointer;

            @include accordionIcons;
        }
        .evp-bottom-content {
            padding: 25px 0;
        }
    }

}

.gfield_description.validation_message {
    position: absolute;
}

#field_5_28 .validation_message, #field_6_18 .validation_message {
    display: none;
}