.career-testimonial-slider {
    background-color: #666668;

    .owl-carousel {
        padding: 30px 35px;
    }

    h4 {
        color: $yellow;
        margin-bottom: 1rem;
        font-style: italic;

        strong {
            font-weight: 500;
        }
    }

    p {
        color: #fff;
        font-style: italic;
    }
}

.available-positions {
    background-color: #ebebeb;
    position: relative;

    h3 {
        font-weight: 500;
    }

    .bg-overlay {
        width: 100%;
        height: 100%;
        background: #e3e3e3;
        position: absolute;
        top: 0;
        opacity: 0.5;
        z-index: 1;
    }

    .ajax-loader {
        background: url(../images/ajax-loader.gif) no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
    }
}


.filter-sidebar {
    @media (min-width: 992px) {
        @include flex(0 0 28.51%);
        max-width: 28.51%;
    }

    .filter-reset {
        padding: 15px 0 5px;
    }

    .widget {
        h6 {
            background-color: #000;
            color: #fff;
            line-height: 40px;
            padding: 0 23px;
            display: block;
            margin: 0;

            @media (min-width: 768px) {
                pointer-events: none;
            }
        }

        .filter-list {
            padding: 15px 23px 30px;

            @media (min-width: 768px) {
                display: block !important;
            }

            .field-label {
                color: #323232;
            }

            span.jqTransformCheckboxWrapper {
                margin-top: 1px;
                margin-right: 12px;
            }

            a.jqTransformCheckbox {
                width: 21px;
                height: 21px;
                border: solid 1px #979797;
                background-color: #fff;
                box-shadow: none;

                &.jqTransformChecked {
                    background-color: $red;
                    border-color: $red;

                    &:before {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.filter-sort {
    .filter-selection {
        select {
            margin-left: 12px;

            @media (min-width: 1200px) {
                width: 300px;
            }
        }
    }

    .filter-pagination {
        margin-left: 30px;

        @media (max-width: 767px) {
            margin: 0 auto !important;
            display: inline-block !important;
        }
    }
}

.job-pagination {
    display: block;
    width: 100%;
    padding: 1rem 0;
    text-align: center;

    @media (max-width: 767px) {
        .filter-pagination {
            display: inline-block !important;
        }
    }
}

.filter-content {
    @media (min-width: 992px) {
        @include flex(0 0 71.49%);
        max-width: 71.49%;
    }

    .filter-sort-list {
        padding: 35px 0 30px;

        @include clearfix;

        .filter-item {
            @include clearfix;
            border-bottom: 2px solid #bcbcbc;
            padding-bottom: 40px;
            margin-bottom: 40px;
            width: 100%;

            @media (min-width: 1200px) {
                padding-left: 7px;
            }

            @media (max-width: 991px) {
                padding-bottom: 30px;
                margin-bottom: 25px;

                >div {
                    float: none !important;
                    width: 100% !important;
                }
            }

            .position-info {
                float: left;
                width: 71.52%;

                h6 {}

                ul {
                    @include clearfix;

                    @media (min-width: 1200px) {
                        padding-left: 10px;
                    }

                    li {
                        float: left;
                        width: 33.3333%;
                        font-size: 14px;

                        @media (max-width: 1199px) {
                            font-size: 13px;

                            &.pi-category {
                                width: 36%;
                            }

                            &.pi-date {
                                width: 30.3333%;
                            }
                        }

                        @media (max-width: 991px) {
                            float: none;
                            width: 100% !important;
                            padding-bottom: 10px;
                            font-size: 14px;

                            &.pi-location {
                                &:before {
                                    margin-left: 4px;
                                    margin-right: 13px;
                                }
                            }

                            &.pi-date {
                                &:before {
                                    margin-left: 3px;
                                    margin-right: 10px;
                                }
                            }
                        }

                        &:before {
                            content: '';
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 8px;
                            margin-top: -3px;
                            background-position: 0 50%;
                            background-repeat: no-repeat;
                            background-size: contain;
                            height: 18px;
                        }

                        &.pi-category {
                            &:before {
                                width: 21px;
                                background-image: url(../images/svg/careers-category-icon.svg);
                            }
                        }

                        &.pi-location {
                            &:before {
                                width: 13px;
                                background-image: url(../images/svg/careers-location-icon.svg);
                            }
                        }

                        &.pi-date {
                            &:before {
                                width: 16px;
                                background-image: url(../images/svg/careers-date-icon.svg);
                            }
                        }
                    }
                }
            }

            .view-listing {
                float: right;
                padding-top: 4px;
                width: 28.48%;

                .btn-primary {
                    width: 100%;

                    @media (max-width: 991px) {
                        width: 200px;
                    }
                }

                @media (max-width: 767px) {
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .available-positions {
        h3 {
            text-align: center;
        }
    }

    .filter-sidebar {
        padding-bottom: 2rem;

        .widget {
            padding-bottom: 12px;

            h6 {
                cursor: pointer;
                position: relative;
                @include toggleIcons;

                &:after {
                    width: 16px;
                    right: 14px;
                }
            }

            .filter-list {
                padding-bottom: 15px;
                display: none;

                &:after {
                    content: '';
                }
            }
        }
    }

    .filter-pagination,
    .filter-sort {
        display: block !important;

        .d-none {
            display: none !important;
        }
    }

    .filter-selection {
        >div {
            display: block !important;

            &:first-child {
                padding-left: 15px;
                padding-bottom: 7px;
            }

            select {
                margin-left: 0 !important;
            }
        }
    }

}

.spinner-search {
    margin: 25vh auto;
    width: 50px;
    height: 70px;
    text-align: center;
    font-size: 10px;

    div {
        background-color: $red;
        height: 100%;
        width: 6px;
        display: inline-block;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .rect2 {
        animation-delay: -1.1s;
    }

    .rect3 {
        animation-delay: -1s;
    }

    .rect4 {
        animation-delay: -.9s;
    }

    .rect5 {
        animation-delay: -.8s;
    }
}

@keyframes sk-stretchdelay {

    0%,
    40%,
    to {
        transform: scaleY(.4);
        -webkit-transform: scaleY(.4)
    }

    20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1)
    }
}

#available-positions-section{
    height: 50px;
    margin-top:-50px;
}