form.jqtransformdone div.rowElem{
	clear:both;
	padding:4px 0px;
}

/* -------------
 * Radios
 * ------------- */
.jqTransformRadioWrapper {
	float: left;
	display:block;
	margin: 7px 2px 0 0;	
	a.jqTransformRadio { 
		background: none;
		border: 1px solid #c6c6c6;
		position: relative;
		height: 18px;
		width: 18px;
		display:block;
		@include border-radius(50%);
		&.jqTransformChecked{
			&:before{
				content: '';
				background-color: #0f99d6;
				height: 8px;
				width: 8px;
				position: absolute;
				top: 50%;
				left: 50%;
				@include XYalign();
				@include border-radius(50%);
			}
		}
		&:hover, &:focus{
			outline: none;
		}
	}
}

/* -------------
 * Checkboxes
 * ------------- */
span.jqTransformCheckboxWrapper{
	display:block;
	float:left;
	margin-right: 5px;
	margin-top:6px;
}
a.jqTransformCheckbox {
	background: none;
	border: 0;
	position: relative;
	height: 18px;
	width: 18px;
	display:block;
    background-color: #fff;
    @include box-shadow( inset 0 0.5px 5px 0 rgba(0, 0, 0, 0.19) );
	&.jqTransformChecked{
		&:before{
			color: $red;
			content: "\f00c";
			font-family: FontAwesome;
			font-size: 15px;
			text-align: center;
			line-height: 18px;
			position: absolute;
			left: 2px;
			top: 0;
		}
		
	}
	&:hover, &:focus{
		outline: none;
	}
}

/* -------------
 * Selects
 * ------------- */
.jqTransformSelectWrapper {
	max-width: 100%;
	width: 100% !important;
	position:relative;
	background: #fff;
	border: 1px solid #c6c6c6;
	padding-right: 40px;
	div span {
		display: block;
		white-space: nowrap;
		line-height: 48px;
		width: 100% !important;
		outline: none;
		padding: 0 10px 0 18px;
		margin: 0 !important;
		overflow: hidden;
		cursor:pointer;
	}
	a.jqTransformSelectOpen {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 40px;
		height: 100%;
		outline: none;
		&:before {
			color: #0f99d6;
			content: "\f107";
			font-family: FontAwesome;
			font-size: 34px;
			text-align: center;
			line-height: 50px;
			position: absolute;
			right: 0;
			left: 0;
			bottom: 0;
			top: 0;
		}
	}
	ul {
		position: absolute;
		width: 100%;
		top: 100%;
		left: 0px;
		list-style: none;
		background-color: #FFF;
		border: solid 1px #B1B1B1;
		display: none;
		margin: 0px;
		padding: 0px;
		height: 270px;
		overflow: auto;
		overflow-y: auto;
		z-index:10;
		a {
			display: block;
			font-size: 1rem;
			font-weight: 400 !important;
			line-height: 1.4;
			padding: 7px 20px;
			text-decoration: none !important;
			color: #000;
			background-color: #FFF;
			&.selected {
				background: #EDEDED;
				color: #333;
			}
			&:hover, &.selected:hover{
				background: $red;
				color: #fff;
			}
		}
	}	
}

/* -------------
 * Hidden - used to hide the original form elements
 * ------------- */
.jqTransformHidden {display: none !important;}