/*** BUTTONS ***/
button,
input[type="submit"],
.btn-primary, 
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle {
	color: $white;
	background-color: $red;
	border-radius: 0; 
	text-transform: uppercase; 
	padding: 0 1.5rem; 
	font-size: 1rem;
	line-height: 54px;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    border: 0;
    transition: $transition-base; 
	&:hover {
		color: $black;
		background-color: $yellow;
        border-color: $yellow;
        text-decoration: none;
	}
	&:focus {
        box-shadow: none;
        outline: none;
    }
    &.hover-black:hover {
		color: $yellow;
		background-color: $black;
        border-color: $black;
	}
}